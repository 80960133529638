export const INSURED_REQUEST_SEARCH = 'INSURED_REQUEST_SEARCH';
export const INSURED_SEARCH_SUCCESS = 'INSURED_SEARCH_SUCCESS';
export const INSURED_SEARCH_FAIL = 'INSURED_SEARCH_FAIL';
export const CLEAN_SEARCH_EXTERNAL = 'CLEAN_SEARCH_EXTERNAL';
/***---------------ACTIONS CREATORS---------------- */
export function searchSuccess(data) {
  return {
    type: INSURED_SEARCH_SUCCESS,
    data
  };
}
export function searchFail() {
  return {
    type: INSURED_SEARCH_FAIL
  };
}

export function cleanSearch() {
  return {
    type: CLEAN_SEARCH_EXTERNAL
  };
}

export function requestSearch(payload) {
  return {
    type: INSURED_REQUEST_SEARCH,
    payload: payload
  };
}
