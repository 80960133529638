export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SAVE_SEARCH = 'SAVE_SEARCH';
export const SEARCH_FAIL = 'SEARCH_FAIL';
export const SEARCH_CLEAN = 'SEARCH_CLEAN';

export function requestSearch(payload) {
  return {
    type: SEARCH_REQUEST,
    payload
  };
}

export function searchSuccess(payload) {
  return {
    type: SEARCH_SUCCESS,
    payload
  };
}

export function saveSearch(payload) {
  return {
    type: SAVE_SEARCH,
    payload
  };
}

export function searchFail() {
  return {
    type: SEARCH_FAIL
  };
}

export function searchClean() {
  return {
    type: SEARCH_CLEAN
  };
}
