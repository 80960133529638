export const AWARDINGS_BYLIST_REQUEST = 'AWARDINGS_BYLIST_REQUEST';
export const AWARDINGS_BYLIST_SUCCESS = 'AWARDINGS_BYLIST_SUCCESS';
export const AWARDINGS_BYLIST_FAIL = 'AWARDINGS_BYLIST_FAIL';

export const AWARDINGS_BYADVERT_REQUEST = 'AWARDINGS_BYADVERT_REQUEST';
export const AWARDINGS_BYADVERT_SUCCESS = 'AWARDINGS_BYADVERT_SUCCESS';
export const AWARDINGS_BYADVERT_FAIL = 'AWARDINGS_BYADVERT_FAIL';

export const AWARD_BYCASE_REQUEST = 'AWARD_BYCASE_REQUEST';
export const AWARD_BYCASE_SUCCESS = 'AWARD_BYCASE_SUCCESS';
export const AWARD_BYCASE_FAIL = 'AWARD_BYCASE_FAIL';
export const AWARD_BYCASE_CLEAN = 'AWARD_BYCASE_CLEAN';

export const AWARD_DEADLINE_REQUEST = 'AWARD_DEADLINE_REQUEST';
export const AWARD_DEADLINE_SUCCESS = 'AWARD_DEADLINE_SUCCESS';
export const AWARD_DEADLINE_FAIL = 'AWARD_DEADLINE_FAIL';

export const AWARD_DELETE_REQUEST = 'AWARD_DELETE_REQUEST';
export const AWARD_DELETE_SUCCESS = 'AWARD_DELETE_SUCCESS';
export const AWARD_DELETE_FAIL = 'AWARD_DELETE_FAIL';

export const AWARD_BIDS_REQUEST = 'AWARD_BIDS_REQUEST';
export const AWARD_BIDS_SUCCESS = 'AWARD_BIDS_SUCCESS';
export const AWARD_BIDS_FAIL = 'AWARD_BIDS_FAIL';
export const AWARD_BIDS_CLEAN = 'AWARD_BIDS_CLEAN';

export const AWARDING_CASE_DETAIL_REQUEST = 'AWARDING_CASE_DETAIL_REQUEST';
export const AWARDING_CASE_DETAIL_SUCCESS = 'AWARDINGS_CASE_DETAIL_SUCCESS';
export const AWARDING_CASE_DETAIL_FAIL = 'AWARDINGS_CASE_DETAIL_FAIL';
export const AWARDING_CASE_DETAIL_CLEAN = 'AWARDINGS_CASE_DETAIL_CLEAN';

export const AWARD_REGISTER_REQUEST = 'AWARD_REGISTER_REQUEST';
export const AWARD_REGISTER_SUCCESS = 'AWARD_REGISTER_SUCCESS';
export const AWARD_REGISTER_FAIL = 'AWARD_REGISTER_FAIL';
export const AWARD_REGISTER_CLEAN = 'AWARD_REGISTER_CLEAN';

export const AWARD_VALIDATE_REQUEST = 'AWARD_VALIDATE_REQUEST';
export const AWARD_VALIDATE_SUCCESS = 'AWARD_VALIDATE_SUCCESS';
export const AWARD_VALIDATE_FAIL = 'AWARD_VALIDATE_FAIL';

export const CLEAN_SEARCH_RESULTS = 'CLEAN_SEARCH_RESULTS';

export const SET_UNITARY_AWARD = 'SET_UNITARY_AWARD';
export const SET_MASSIVE_UNITARY_AWARD = 'SET_MASSIVE_UNITARY_AWARD';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_UNITARY_AWARD_ALL = 'SET_UNITARY_AWARD_ALL';
export const SET_UNIQUE_ADVERT_ID = 'SET_UNIQUE_ADVERT_ID';

export const AWARD_HISTORY_REQUEST = 'AWARD_HISTORY_REQUEST';
export const AWARD_HISTORY_SUCCESS = 'AWARD_HISTORY_SUCCESS';
export const AWARD_HISTORY_FAIL = 'AWARD_HISTORY_FAIL';
export const AWARD_HISTORY_CLEAN = 'AWARD_HISTORY_CLEAN';

export const AWARD_UPDATE_REQUEST = 'AWARD_UPDATE_REQUEST';
export const AWARD_UPDATE_SUCCESS = 'AWARD_UPDATE_SUCCESS';
export const AWARD_UPDATE_FAIL = 'AWARD_UPDATE_FAIL';

export const SET_VIEW_TYPE = 'SET_VIEW_TYPE';

export const SET_CURRENT_DATA = 'SET_CURRENT_DATA';

export const SET_BYLIST_FILTERS = 'SET_BYLIST_FILTERS';
export const SET_BYUNITARY_FILTERS = 'SET_BYUNITARY_FILTERS';

export const DOWNLOAD_EXCEL_REPORT_PAYMENTS_REQUEST = 'DOWNLOAD_EXCEL_REPORT_PAYMENTS_REQUEST';
export const DOWNLOAD_EXCEL_REPORT_PAYMENTS_SUCCESS = 'DOWNLOAD_EXCEL_REPORT_PAYMENTS_SUCCESS';
export const DOWNLOAD_EXCEL_REPORT_PAYMENTS_FAIL = 'DOWNLOAD_EXCEL_REPORT_PAYMENTS_FAIL';

export const RESET_UNITARY_FILTERS = 'RESET_UNITARY_FILTERS';
export const RESET_BY_LIST_FILTERS = 'RESET_BY_LIST_FILTERS';

export function setViewType(payload) {
  return {
    type: SET_VIEW_TYPE,
    payload
  };
}

export function setSearchQuery(payload) {
  return {
    type: SET_SEARCH_QUERY,
    payload
  };
}

//caseId y card
export function setUnitaryAwardAll(payload) {
  return {
    type: SET_UNITARY_AWARD_ALL,
    payload
  };
}

export function setMassiveUnitaryAward(payload) {
  return {
    type: SET_MASSIVE_UNITARY_AWARD,
    payload
  };
}

export function setUnitaryAward(payload) {
  return {
    type: SET_UNITARY_AWARD,
    payload
  };
}

export function cleanSearchResults(payload) {
  return {
    type: CLEAN_SEARCH_RESULTS,
    payload
  };
}

export function awardDeleteRequest(payload) {
  return {
    type: AWARD_DELETE_REQUEST,
    payload
  };
}

export function awardDeleteSuccess(payload) {
  return {
    type: AWARD_DELETE_SUCCESS,
    payload
  };
}

export function awardDeleteFail(payload) {
  return {
    type: AWARD_DELETE_FAIL,
    payload
  };
}

export function awardDeadlineRequest(payload) {
  return {
    type: AWARD_DEADLINE_REQUEST,
    payload
  };
}

export function awardDeadlineSuccess(payload) {
  return {
    type: AWARD_DEADLINE_SUCCESS,
    payload
  };
}

export function awardDeadlineFail(payload) {
  return {
    type: AWARD_DEADLINE_FAIL,
    payload
  };
}

export function awardingsByListRequest(payload) {
  return {
    type: AWARDINGS_BYLIST_REQUEST,
    payload
  };
}

export function awardingsByListSuccess(payload) {
  return {
    type: AWARDINGS_BYLIST_SUCCESS,
    payload
  };
}

export function awardingsByListFail(payload) {
  return {
    type: AWARDINGS_BYLIST_FAIL,
    payload
  };
}

export function awardingsByAdvertRequest(payload) {
  return {
    type: AWARDINGS_BYADVERT_REQUEST,
    payload
  };
}

export function awardingsByAdvertSuccess(payload) {
  return {
    type: AWARDINGS_BYADVERT_SUCCESS,
    payload
  };
}

export function awardingsByAdvertFail(payload) {
  return {
    type: AWARDINGS_BYADVERT_FAIL,
    payload
  };
}

export function awardByCaseRequest(payload) {
  return {
    type: AWARD_BYCASE_REQUEST,
    payload
  };
}

export function awardByCaseSucess(payload) {
  return {
    type: AWARD_BYCASE_SUCCESS,
    payload
  };
}

export function awardByCaseFail() {
  return {
    type: AWARD_BYCASE_FAIL
  };
}

export function awardByCaseClean() {
  return {
    type: AWARD_BYCASE_CLEAN
  };
}

export function awardBidsRequest(payload) {
  return {
    type: AWARD_BIDS_REQUEST,
    payload
  };
}

export function awardBidsSuccess(payload) {
  return {
    type: AWARD_BIDS_SUCCESS,
    payload
  };
}

export function awardBidsFail() {
  return {
    type: AWARD_BIDS_FAIL
  };
}

export function awardBidsClean() {
  return {
    type: AWARD_BIDS_CLEAN
  };
}

export function awardingCaseDetailRequest(payload) {
  return {
    type: AWARDING_CASE_DETAIL_REQUEST,
    payload
  };
}

export function awardingCaseDetailSuccess(payload) {
  return {
    type: AWARDING_CASE_DETAIL_SUCCESS,
    payload
  };
}

export function awardingCaseDetailFail() {
  return {
    type: AWARDING_CASE_DETAIL_FAIL
  };
}

export function awardingCaseDetailClean() {
  return {
    type: AWARDING_CASE_DETAIL_CLEAN
  };
}

export function awardRegisterRequest(payload) {
  return {
    type: AWARD_REGISTER_REQUEST,
    payload
  };
}

export function awardRegisterSuccess(payload) {
  return {
    type: AWARD_REGISTER_SUCCESS,
    payload
  };
}

export function awardRegisterFail() {
  return {
    type: AWARD_REGISTER_FAIL
  };
}

export function awardRegisterClean() {
  return {
    type: AWARD_REGISTER_CLEAN
  };
}

export function awardValidateRequest(payload) {
  return {
    type: AWARD_VALIDATE_REQUEST,
    payload
  };
}

export function awardValidateSuccess(payload) {
  return {
    type: AWARD_VALIDATE_SUCCESS,
    payload
  };
}

export function awardValidateFail() {
  return {
    type: AWARD_VALIDATE_FAIL
  };
}

export function awardHistoryRequest(payload) {
  return {
    type: AWARD_HISTORY_REQUEST,
    payload
  };
}

export function awardHistorySuccess(payload) {
  return {
    type: AWARD_HISTORY_SUCCESS,
    payload
  };
}

export function awardHistoryFail() {
  return {
    type: AWARD_HISTORY_FAIL
  };
}

export function awardHistoryClean() {
  return {
    type: AWARD_HISTORY_CLEAN
  };
}

export function setUniqueAdvertId(payload) {
  return {
    type: SET_UNIQUE_ADVERT_ID,
    payload
  };
}

export function awardUpdateRequest(payload) {
  return {
    type: AWARD_UPDATE_REQUEST,
    payload
  };
}

export function awardUpdateSuccess(payload) {
  return {
    type: AWARD_UPDATE_SUCCESS,
    payload
  };
}

export function awardUpdateFail(payload) {
  return {
    type: AWARD_UPDATE_FAIL,
    payload
  };
}

export const setCurrentData = number => ({
  type: SET_CURRENT_DATA,
  number
});

export const setByListFilter = (payload) => ({
  type: SET_BYLIST_FILTERS,
  payload
});

export const setByUnitaryFilter = (payload) => ({
  type: SET_BYUNITARY_FILTERS,
  payload
});

export const downloadReportExcelRequest = payload => ({
  type: DOWNLOAD_EXCEL_REPORT_PAYMENTS_REQUEST,
  payload
});

export const downloadReportExcelSuccess = payload => ({
  type: DOWNLOAD_EXCEL_REPORT_PAYMENTS_SUCCESS,
  payload
});

export const downloadReportExcelFail = payload => ({
  type: DOWNLOAD_EXCEL_REPORT_PAYMENTS_FAIL,
  payload
});

export const resetUnitaryFilters = () => ({ 
  type: RESET_UNITARY_FILTERS,
});

export const resetByListFilters = () => ({
  type: RESET_BY_LIST_FILTERS,
});