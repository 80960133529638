export const DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL';

export const DOWNLOAD_FILE_PREVIEW_REQUEST = 'DOWNLOAD_FILE_PREVIEW_REQUEST';
export const DOWNLOAD_FILE_PREVIEW_SUCCESS = 'DOWNLOAD_FILE_PREVIEW_SUCCESS';
export const DOWNLOAD_FILE_PREVIEW_FAIL = 'DOWNLOAD_FILE_PREVIEW_FAIL';

export const BACKGROUND_DOWNLOAD_SUCCESS = 'BACKGROUND_DOWNLOAD_SUCCESS';

export function requestDownloadFile(payload) {
  return {
    type: DOWNLOAD_FILE_REQUEST,
    payload
  };
}

export function downloadFileSuccess(payload) {
  return {
    type: DOWNLOAD_FILE_SUCCESS,
    payload
  };
}

export function downloadFileFail() {
  return {
    type: DOWNLOAD_FILE_FAIL
  };
}
export function backgroundDownloadSuccess(payload) {
  return {
    type: BACKGROUND_DOWNLOAD_SUCCESS,
    payload
  };
}

export function requestDownloadFilePreview(payload) {
  return {
    type: DOWNLOAD_FILE_PREVIEW_REQUEST,
    payload
  };
}

export function downloadFilePreviewSuccess(payload) {
  return {
    type: DOWNLOAD_FILE_PREVIEW_SUCCESS,
    payload
  };
}

export function downloadFilePreviewFail(payload) {
  return {
    type: DOWNLOAD_FILE_PREVIEW_FAIL,
    payload
  };
}
