export const LOCATIONS_REQUEST = 'LOCATIONS_REQUEST';
export const LOCATIONS_SUCCESS = 'LOCATIONS_SUCCESS';
export const LOCATIONS_FAIL = 'LOCATIONS_FAIL';

export function locationsRequest(payload) {
  return {
    type: LOCATIONS_REQUEST,
    payload
  };
}
export function locationsSuccess(payload) {
  return {
    type: LOCATIONS_SUCCESS,
    payload
  };
}
export function locationsFail() {
  return {
    type: LOCATIONS_FAIL
  };
}
