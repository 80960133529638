export const ROLES_GROUP_REQUEST = 'ROLES_GROUP_REQUEST';
export const ROLES_SUCCESS_GROUP = 'ROLES_SUCCESS_GROUP';
export const ROLES_FAIL_GROUP = 'ROLES_FAIL_GROUP';
export const SET_ROLES_DATA = 'SET_ROLES_DATA';

export const SET_CATALOG_FILTER = 'SET_CATALOG_FILTER';

export const PROFILES_REQUEST = 'PROFILES_REQUEST';
export const PROFILES_SUCCESS = 'PROFILES_SUCCESS';
export const PROFILES_FAIL = 'PROFILES_FAIL';

export const REQUEST_COUNTRIES = 'REQUEST_COUNTRIES';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
export const COUNTRIES_FAIL = 'COUNTRIES_FAIL';
export const REQUEST_DEPARTMENTS = 'REQUEST_DEPARTMENTS';
export const DEPARTMENTS_SUCCESS = 'DEPARTMENTS_SUCCESS';
export const DEPARTMENTS_FAIL = 'DEPARTMENTS_FAIL';
export const REQUEST_JOBS = 'REQUEST_JOBS';
export const JOBS_SUCCESS = 'JOBS_SUCCESS';
export const JOBS_FAIL = 'JOBS_FAIL';
export const REQUEST_STATUS_USERS = 'REQUEST_STATUS_USERS';
export const STATUS_USERS_SUCCESS = 'STATUS_USERS_SUCCESS';
export const STATUS_USERS_FAIL = 'STATUS_USERS_FAIL';
export const REQUEST_MARKETS = 'REQUEST_MARKETS';
export const REQUEST_MARKETSALL = 'REQUEST_MARKETSALL';
export const MARKETS_SUCCESS = 'MARKETS_SUCCESS';
export const MARKETS_FAIL = 'MARKETS_FAIL';
export const REQUEST_LANGUAGES = 'REQUEST_LANGUAGES';
export const LANGUAGES_SUCCESS = 'LANGUAGES_SUCCESS';
export const LANGUAGES_FAIL = 'LANGUAGES_FAIL';
export const REQUEST_REGIMES = 'REQUEST_REGIMES';
export const REGIMES_SUCCESS = 'REGIMES_SUCCESS';
export const REGIMES_FAIL = 'REGIMES_FAIL';
export const REQUEST_CANCEL_REASONS = 'REQUEST_CANCEL_REASONS';
export const CANCEL_REASONS_SUCCESS = 'CANCEL_REASONS_SUCCESS';
export const CANCEL_REASONS_FAIL = 'CANCEL_REASONS_FAIL';
export const REQUEST_TIMEZONES = 'REQUEST_TIMEZONES';
export const TIMEZONES_SUCCESS = 'TIMEZONES_SUCCESS';
export const TIMEZONES_FAIL = 'TIMEZONES_FAIL';

export const VEHICLES_TYPE_REQUEST = 'VEHICLES_TYPE_REQUEST';
export const VEHICLES_TYPE_SUCCESS = 'VEHICLES_TYPE_SUCCESS';
export const VEHICLES_TYPE_FAIL = 'VEHICLES_TYPE_FAIL';

export const ENGINE_TYPE_REQUEST = 'ENGINE_TYPE_REQUEST';
export const ENGINE_TYPE_SUCCESS = 'ENGINE_TYPE_SUCCESS';
export const ENGINE_TYPE_FAIL = 'ENGINE_TYPE_FAIL';

export const STATES_REQUEST = 'STATES_REQUEST';
export const STATES_SUCCESS = 'STATES_SUCCESS';
export const STATES_FAIL = 'STATES_FAIL';

export const OFFER_TYPE_REQUEST = 'OFFER_TYPE_REQUEST';
export const OFFER_TYPE_SUCCESS = 'OFFER_TYPE_SUCCESS';
export const OFFER_TYPE_FAIL = 'OFFER_TYPE_FAIL';

export const SUPPLIERS_BY_SERVICE_TYPE_REQUEST = 'SUPPLIERS_BY_SERVICE_TYPE_REQUEST';
export const SUPPLIERS_BY_SERVICE_TYPE_SUCCESS = 'SUPPLIERS_BY_SERVICE_TYPE_SUCCESS';
export const SUPPLIERS_BY_SERVICE_TYPE_FAIL = 'SUPPLIERS_BY_SERVICE_TYPE_FAIL';

export const BRANCHES_BY_PROVIDER_ID_REQUEST = 'BRANCHES_BY_PROVIDER_ID_REQUEST';
export const BRANCHES_BY_PROVIDER_ID_SUCCESS = 'BRANCHES_BY_PROVIDER_ID_SUCCESS';
export const BRANCHES_BY_PROVIDER_ID_FAIL = 'BRANCHES_BY_PROVIDER_ID_FAIL';

export const BRANCHES_BY_PROVIDER_ID_CLEAN = 'BRANCHES_BY_PROVIDER_ID_CLEAN';

export const GET_VEHICLE_PARTS_REQUEST = 'GET_VEHICLE_PARTS_REQUEST';
export const GET_VEHICLE_PARTS_SUCCESS = 'GET_VEHICLE_PARTS_SUCCESS';
export const GET_VEHICLE_PARTS_FAIL = 'GET_VEHICLE_PARTS_FAIL';

export const CATALOG_DETAIL_REQUEST = 'CATALOG_DETAIL_REQUEST';
export const CATALOG_DETAIL_SUCCESS = 'CATALOG_DETAIL_SUCCESS';
export const CATALOG_DETAIL_FAIL = 'CATALOG_DETAIL_FAIL';

export const PAYMENTS_STATUS_REQUEST = 'PAYMENTS_STATUS_REQUEST';
export const PAYMENTS_STATUS_SUCCESS = 'PAYMENTS_STATUS_SUCCESS';
export const PAYMENTS_STATUS_FAIL = 'PAYMENTS_STATUS_FAIL';

export const ACTION_CATALOG_REQUEST = 'ACTION_CATALOG_REQUEST';
export const ACTION_CATALOG_SUCCESS = 'ACTION_CATALOG_SUCCESS';
export const ACTION_CATALOG_FAIL = 'ACTION_CATALOG_FAIL';
export const ACTION_CATALOG_CLEAN = 'ACTION_CATALOG_CLEAN';

export const DOCUMENTS_GROUP_REQUEST = 'DOCUMENTS_GROUP_REQUEST';
export const DOCUMENTS_GROUP_SUCCESS = 'DOCUMENTS_GROUP_SUCCESS';
export const DOCUMENTS_GROUP_FAIL = 'DOCUMENTS_GROUP_FAIL';

export const DOCUMENT_TYPES_REQUEST = 'DOCUMENT_TYPES_REQUEST';
export const DOCUMENT_TYPES_SUCCESS = 'DOCUMENT_TYPES_SUCCESS';
export const DOCUMENT_TYPES_FAIL = 'DOCUMENT_TYPES_FAIL';

export const WRECK_TYPES_REQUEST = 'WRECK_TYPES_REQUEST';
export const WRECK_TYPES_SUCCESS = 'WRECK_TYPES_SUCCESS';
export const WRECK_TYPES_FAIL = 'WRECK_TYPES_FAIL';

export const STATUS_TRANSFERS_REQUEST = 'STATUS_TRANSFERS_REQUEST';
export const STATUS_TRANSFERS_SUCCESS = 'STATUS_TRANSFERS_SUCCESS';
export const STATUS_TRANSFERS_FAIL = 'STATUS_TRANSFERS_FAIL';

export const DAMAGE_PARTS_REQUEST = 'DAMAGE_PARTS_REQUEST';
export const DAMAGE_PARTS_SUCCESS = 'DAMAGE_PARTS_SUCCESS';
export const DAMAGE_PARTS_FAIL = 'DAMAGE_PARTS_FAIL';

export const OTHER_SUB_WRECK_TYPES_REQUEST = 'OTHER_SUB_WRECK_TYPES_REQUEST';
export const OTHER_SUB_WRECK_TYPES_SUCCESS = 'OTHER_SUB_WRECK_TYPES_SUCCESS';
export const OTHER_SUB_WRECK_TYPES_FAIL = 'OTHER_SUB_WRECK_TYPES_FAIL';

export function wreckTypesRequest(payload) {
  return {
    type: WRECK_TYPES_REQUEST,
    payload
  };
}
export function wreckTypesSuccess(payload) {
  return {
    type: WRECK_TYPES_SUCCESS,
    payload
  };
}
export function wreckTypesFail() {
  return {
    type: WRECK_TYPES_FAIL
  };
}

export const SUB_WRECK_TYPES_REQUEST = 'SUB_WRECK_TYPES_REQUEST';
export const SUB_WRECK_TYPES_SUCCESS = 'SUB_WRECK_TYPES_SUCCESS';
export const SUB_WRECK_TYPES_FAIL = 'SUB_WRECK_TYPES_FAIL';

export const SUPPLIERS_BY_SERVICE_TYPE_GRAL_REQUEST = 'SUPPLIERS_BY_SERVICE_TYPE_GRAL_REQUEST';
export const SUPPLIERS_BY_SERVICE_TYPE_GRAL_SUCCESS = 'SUPPLIERS_BY_SERVICE_TYPE_GRAL_SUCCESS';
export const SUPPLIERS_BY_SERVICE_TYPE_GRAL_FAIL = 'SUPPLIERS_BY_SERVICE_TYPE_GRAL_FAIL';

export const BRANCHES_CRANE_BY_PROVIDER_ID_REQUEST = 'BRANCHES_CRANE_BY_PROVIDER_ID_REQUEST';
export const BRANCHES_CRANE_BY_PROVIDER_ID_SUCCESS = 'BRANCHES_CRANE_BY_PROVIDER_ID_SUCCESS';
export const BRANCHES_CRANE_BY_PROVIDER_ID_FAIL = 'BRANCHES_CRANE_BY_PROVIDER_ID_FAIL';

export const BUYERS_BY_INSURANCE_CARRIER_ID_REQUEST = 'BUYERS_BY_INSURANCE_CARRIER_ID_REQUEST';
export const BUYERS_BY_INSURANCE_CARRIER_ID_SUCCESS = 'BUYERS_BY_INSURANCE_CARRIER_ID_SUCCESS';
export const BUYERS_BY_INSURANCE_CARRIER_ID_FAIL = 'BUYERS_BY_INSURANCE_CARRIER_ID_FAIL';

export const INCIDENCE_TYPE_REQUEST = 'INCIDENCE_TYPE_REQUEST';
export const INCIDENCE_TYPE_SUCCESS = 'INCIDENCE_TYPE_SUCCESS';
export const INCIDENCE_TYPE_FAIL = 'INCIDENCE_TYPE_FAIL';

export function subWreckTypesRequest(payload) {
  return {
    type: SUB_WRECK_TYPES_REQUEST,
    payload
  };
}
export function subWreckTypesSuccess(payload) {
  return {
    type: SUB_WRECK_TYPES_SUCCESS,
    payload
  };
}
export function subWreckTypesFail() {
  return {
    type: SUB_WRECK_TYPES_FAIL
  };
}

export function documentTypesRequest(payload) {
  return {
    type: DOCUMENT_TYPES_REQUEST,
    payload
  };
}
export function documentTypesSuccess(payload) {
  return {
    type: DOCUMENT_TYPES_SUCCESS,
    payload
  };
}
export function documentTypesFail() {
  return {
    type: DOCUMENT_TYPES_FAIL
  };
}

export function documentsGroupRequest(payload) {
  return {
    type: DOCUMENTS_GROUP_REQUEST,
    payload
  };
}
export function documentsGroupSuccess(payload) {
  return {
    type: DOCUMENTS_GROUP_SUCCESS,
    payload
  };
}
export function documentsGroupFail() {
  return {
    type: DOCUMENTS_GROUP_FAIL
  };
}

/**start roles */
export function requestRoles() {
  return {
    type: ROLES_GROUP_REQUEST
  };
}
export function rolesSuccess(profiles) {
  return {
    type: ROLES_SUCCESS_GROUP,
    profiles
  };
}
export function rolesFail() {
  return {
    type: ROLES_FAIL_GROUP
  };
}
export function setRolesData(profiles) {
  return {
    type: SET_ROLES_DATA,
    profiles
  };
}
/**end roles */
export function setCatalogFilter(payload) {
  return {
    type: SET_CATALOG_FILTER,
    payload
  };
}
/**start profiles */
export function requestProfiles(id) {
  return {
    type: PROFILES_REQUEST,
    id
  };
}
export function profilesSuccess(profiles) {
  return {
    type: PROFILES_SUCCESS,
    profiles
  };
}
export function profilesFail() {
  return {
    type: PROFILES_FAIL
  };
}
/**end profiles */

/**start countries */
export function requestCountries() {
  return {
    type: REQUEST_COUNTRIES
  };
}
export function countriesSuccess(countries) {
  return {
    type: COUNTRIES_SUCCESS,
    countries
  };
}
export function countriesFail() {
  return {
    type: COUNTRIES_FAIL
  };
}
/**end countries */
/**start departments */
export function requestDepartments() {
  return {
    type: REQUEST_DEPARTMENTS
  };
}
export function departmentsSuccess(departments) {
  return {
    type: DEPARTMENTS_SUCCESS,
    departments
  };
}

export function departmentsFail() {
  return {
    type: DEPARTMENTS_FAIL
  };
}
/**end departments */

/**start jobs */
export function jobsFail() {
  return {
    type: JOBS_FAIL
  };
}
export function jobsSuccess(jobs) {
  return {
    type: JOBS_SUCCESS,
    jobs
  };
}

export function requestJobs(payload) {
  return {
    type: REQUEST_JOBS,
    payload: payload
  };
}

/**start status */
export function requestStatusUser() {
  return {
    type: REQUEST_STATUS_USERS
  };
}
export function statusUsersSuccess(data) {
  return {
    type: STATUS_USERS_SUCCESS,
    status: data
  };
}
export function statusUsersFail() {
  return {
    type: STATUS_USERS_FAIL
  };
}

export function vehiclesTypeRequest(payload) {
  return {
    type: VEHICLES_TYPE_REQUEST,
    payload: payload
  };
}
export function vehiclesTypeSucess(payload) {
  return {
    type: VEHICLES_TYPE_SUCCESS,
    payload: payload
  };
}
export function vehiclesTypeFail() {
  return {
    type: VEHICLES_TYPE_FAIL
  };
}

export function engineTypeRequest() {
  return {
    type: ENGINE_TYPE_REQUEST
  };
}
export function engineTypeSucess(payload) {
  return {
    type: ENGINE_TYPE_SUCCESS,
    payload: payload
  };
}
export function engineTypeFail() {
  return {
    type: ENGINE_TYPE_FAIL
  };
}

export function offerTypeRequest(payload) {
  return {
    type: OFFER_TYPE_REQUEST,
    payload
  };
}
export function offerTypeSucess(payload) {
  return {
    type: OFFER_TYPE_SUCCESS,
    payload: payload
  };
}
export function offerTypeFail() {
  return {
    type: OFFER_TYPE_FAIL
  };
}

export function statesRequest(payload) {
  return {
    type: STATES_REQUEST,
    payload: payload
  };
}
export function statesSucess(payload) {
  return {
    type: STATES_SUCCESS,
    payload: payload
  };
}
export function statesFail() {
  return {
    type: STATES_FAIL
  };
}

/**start markets */
export function requestMarkets(payload = null) {
  return {
    type: REQUEST_MARKETS,
    payload
  };
}

export function requestMarketsAll() {
  return {
    type: REQUEST_MARKETSALL
  };
}
export function marketsSuccess(markets) {
  return {
    type: MARKETS_SUCCESS,
    markets
  };
}
export function marketsFail() {
  return {
    type: MARKETS_FAIL
  };
}
/**end markets */

/**START  REQUEST_SUPPLIERS_BY_SERVICE_TYPE */
export function requestSupplierByServiceType(payload) {
  return {
    type: SUPPLIERS_BY_SERVICE_TYPE_REQUEST,
    payload: { serviceTypeId: payload.serviceTypeId, ...payload }
  };
}

export function supplierByServiceTypeSuccess(payload) {
  return {
    type: SUPPLIERS_BY_SERVICE_TYPE_SUCCESS,
    payload
  };
}

export function supplierByServiceTypeFail() {
  return {
    type: SUPPLIERS_BY_SERVICE_TYPE_FAIL
  };
}

/**END  REQUEST_SUPPLIERS_BY_SERVICE_TYPE  */

/** start BranchesByProviderId */

export function requestBranchesByProviderId({ providerId, type, withAllOption }) {
  return {
    type: BRANCHES_BY_PROVIDER_ID_REQUEST,
    payload: { providerId, type, withAllOption }
  };
}

export function branchesByProviderIdSuccess(payload) {
  return {
    type: BRANCHES_BY_PROVIDER_ID_SUCCESS,
    payload
  };
}

export function branchesByProviderIdFail() {
  return {
    type: BRANCHES_BY_PROVIDER_ID_FAIL
  };
}

export function branchesByProviderClean() {
  return {
    type: BRANCHES_BY_PROVIDER_ID_CLEAN
  };
}
/** end branches  */

export function branchesCraneByProviderIdRequest({ providerId, type, withAllOption }) {
  return {
    type: BRANCHES_CRANE_BY_PROVIDER_ID_REQUEST,
    payload: { providerId, type, withAllOption }
  };
}

export function branchesCraneByProviderIdSuccess(payload) {
  return {
    type: BRANCHES_CRANE_BY_PROVIDER_ID_SUCCESS,
    payload
  };
}

export function branchesCraneByProviderIdFail() {
  return {
    type: BRANCHES_CRANE_BY_PROVIDER_ID_FAIL
  };
}

/** start vehicles parts */
export function getVehiclePartsRequest(id) {
  return {
    type: GET_VEHICLE_PARTS_REQUEST,
    id
  };
}
export function getVehiclePartsSuccess(payload) {
  return {
    type: GET_VEHICLE_PARTS_SUCCESS,
    payload
  };
}
export function getVehiclePartsFail() {
  return {
    type: GET_VEHICLE_PARTS_FAIL
  };
}
/** end  vehicles parts */
/** start catalog detail */
export function catalogDetailRequest(payload) {
  return {
    type: CATALOG_DETAIL_REQUEST,
    payload
  };
}

export function catalogDetailSuccess(payload) {
  return {
    type: CATALOG_DETAIL_SUCCESS,
    payload
  };
}

export function catalogDetailFail(payload) {
  return {
    type: CATALOG_DETAIL_FAIL,
    payload
  };
}
/**end  catalog detail*/

/** start payments status */
export function paymentsStatusRequest() {
  return {
    type: PAYMENTS_STATUS_REQUEST
  };
}

export function paymentsStatusSuccess(payload) {
  return {
    type: PAYMENTS_STATUS_SUCCESS,
    payload
  };
}

export function paymentsStatusFail() {
  return {
    type: PAYMENTS_STATUS_FAIL
  };
}

export function actionCatalogRequest(payload) {
  return {
    type: ACTION_CATALOG_REQUEST,
    payload
  };
}

export function actionCatalogSuccess() {
  return {
    type: ACTION_CATALOG_SUCCESS
  };
}

export function actionCatalogFail() {
  return {
    type: ACTION_CATALOG_FAIL
  };
}
export function actionCatalogClean() {
  return {
    type: ACTION_CATALOG_CLEAN
  };
}
/** end payments status  */

/**start languages */
export function requestLanguages() {
  return {
    type: REQUEST_LANGUAGES
  };
}
export function languagesSuccess(languages) {
  return {
    type: LANGUAGES_SUCCESS,
    languages
  };
}
export function languagesFail() {
  return {
    type: LANGUAGES_FAIL
  };
}
/**end languages */

/**start regimes */
export function requestRegimes(payload) {
  return {
    type: REQUEST_REGIMES,
    payload
  };
};

export function regimesSuccess(regimes) {
  return {
    type: REGIMES_SUCCESS,
    regimes
  };
};

export function regimesFail() {
  return {
    type: REGIMES_FAIL
  };
};
/**end regimes */

/**start cancel reasons */

export function requestCancelReasons(payload) {
  return {
    type: REQUEST_CANCEL_REASONS,
    payload
  };
}

export function cancelReasonsSuccess(cancelReasons) {
  return {
    type: CANCEL_REASONS_SUCCESS,
    cancelReasons
  };
}

export function cancelReasonsFail() {
  return {
    type: CANCEL_REASONS_FAIL
  };
}
/**end cancel reasons */

/**start timeZones */
export function requestTimeZones() {
  return {
    type: REQUEST_TIMEZONES
  };
}
export function timeZonesSuccess(timeZones) {
  return {
    type: TIMEZONES_SUCCESS,
    timeZones
  };
}
export function timeZonesFail() {
  return {
    type: TIMEZONES_FAIL
  };
}
/**end timeZones */

export function statusTransfersRequest(payload) {
  return {
    type: STATUS_TRANSFERS_REQUEST,
    payload
  };
}

export function statusTransfersSuccess(payload) {
  return {
    type: STATUS_TRANSFERS_SUCCESS,
    payload
  };
}

export function statusTransfersFail() {
  return {
    type: STATUS_TRANSFERS_FAIL
  };
}

export function supplierByServiceTypeGralRequest(payload) {
  return {
    type: SUPPLIERS_BY_SERVICE_TYPE_GRAL_REQUEST,
    payload: { serviceTypeId: payload.serviceTypeId, ...payload }
  };
}

export function supplierByServiceTypeGralSuccess(payload) {
  return {
    type: SUPPLIERS_BY_SERVICE_TYPE_GRAL_SUCCESS,
    payload
  };
}

export function supplierByServiceTypeGralFail() {
  return {
    type: SUPPLIERS_BY_SERVICE_TYPE_GRAL_FAIL
  };
}

// Buyers by insurance carrier id
export function buyerByInsuranceRequest(payload) {
  return {
    type: BUYERS_BY_INSURANCE_CARRIER_ID_REQUEST,
    payload
  };
}

export function buyerByInsuranceSuccess(payload) {
  return {
    type: BUYERS_BY_INSURANCE_CARRIER_ID_SUCCESS,
    payload
  };
}

export function buyerByInsuranceFail() {
  return {
    type: BUYERS_BY_INSURANCE_CARRIER_ID_FAIL
  };
}

// Incidence type
export function incidenceTypeRequest(payload) {
  return {
    type: INCIDENCE_TYPE_REQUEST,
    payload
  };
}

export function incidenceTypeSuccess(payload) {
  return {
    type: INCIDENCE_TYPE_SUCCESS,
    payload
  };
}

export function incidenceTypeFail() {
  return {
    type: INCIDENCE_TYPE_FAIL
  };
}

export function damagePartsRequest(payload) {
  return {
    type: DAMAGE_PARTS_REQUEST,
    payload
  };
}

export function damagePartsSuccess(payload) {
  return {
    type: DAMAGE_PARTS_SUCCESS,
    payload
  };
}

export function damagePartsFail() {
  return {
    type: DAMAGE_PARTS_FAIL
  };
}

export function otherSubWreckTypesRequest(payload) {
  return {
    type: OTHER_SUB_WRECK_TYPES_REQUEST,
    payload
  };
}

export function otherSubWreckTypesSuccess(payload) {
  return {
    type: OTHER_SUB_WRECK_TYPES_SUCCESS,
    payload
  };
}

export function otherSubWreckTypesFail() {
  return {
    type: OTHER_SUB_WRECK_TYPES_FAIL
  };
}
