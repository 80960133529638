import { CASE_STATUS_FAIL } from './searchCases';

export const REQUEST_DIVERSES_FILES = 'REQUEST_DIVERSES_FILES';
export const DIVERSES_FILES_SUCCESS = 'DIVERSES_FILES_SUCCESS';
export const DIVERSES_FILES_FAIL = 'DIVERSES_FILES_FAIL';

export const REQUEST_UPLOAD_DIVERSES_FILE = 'REQUEST_UPLOAD_DIVERSES_FILE';
export const UPLOAD_DIVERSES_FILE_SUCCESS = 'UPLOAD_DIVERSES_FILE_SUCCESS';
export const UPLOAD_DIVERSES_FILE_FAIL = 'UPLOAD_DIVERSES_FILE_FAIL';

export const REQUEST_DOWNLOAD_DIVERSES_FILE = 'REQUEST_DOWNLOAD_DIVERSES_FILE';
export const DOWNLOAD_DIVERSES_FILE_SUCCESS = 'DOWNLOAD_DIVERSES_FILE_SUCCESS';
export const DOWNLOAD_DIVERSES_FILE_FAIL = 'DOWNLOAD_DIVERSES_FILE_FAIL';

export const REQUEST_CASE_IMAGES = 'REQUEST_CASE_IMAGES';
export const CASE_IMAGES_SUCCESS = 'CASE_IMAGES_SUCCESS';
export const CASE_IMAGES_FAIL = 'CASE_IMAGES_FAIL';

export const REQUEST_CLOSE_CASE = 'REQUEST_CLOSE_CASE';
export const CASE_CLOSE_SUCCESS = 'CASE_CLOSE_SUCCESS';
export const CASE_CLOSE_FAIL = 'CASE_CLOSE_FAIL';

export const REQUEST_UPLOAD_CASE_PHOTO = 'REQUEST_UPLOAD_CASE_PHOTO';
export const UPLOAD_CASE_PHOTO_SUCCESS = 'UPLOAD_CASE_PHOTO_SUCCESS';
export const UPLOAD_CASE_PHOTO_FAIL = 'UPLOAD_CASE_PHOTO_FAIL';

export const REQUEST_DELETE_CASE_PHOTO = 'REQUEST_DELETE_CASE_PHOTO';
export const DELETE_CASE_PHOTO_SUCCESS = 'DELETE_CASE_PHOTO_SUCCESS';
export const DELETE_CASE_PHOTO_FAIL = 'DELETE_CASE_PHOTO_FAIL';

export const REQUEST_UPDATE_DIVERSES = 'REQUEST_UPDATE_DIVERSES';
export const UPDATE_DIVERSES_SUCCESS = 'UPDATE_DIVERSES_SUCCESS';
export const UPDATE_DIVERSES_FAIL = 'UPDATE_DIVERSES_FAIL';

export const REQUEST_FAVORITE_CASE_PHOTO = 'REQUEST_FAVORITE_CASE_PHOTO';
export const FAVORITE_CASE_PHOTO_SUCCESS = 'FAVORITE_CASE_PHOTO_SUCCESS';
export const FAVORITE_CASE_PHOTO_FAIL = 'FAVORITE_CASE_PHOTO_FAIL';

export const REQUEST_COVER_PHOTO = 'REQUEST_COVER_PHOTO';
export const CHANGE_CASE_PHOTOS = 'CHANGE_CASE_PHOTOS';
export const COVER_PHOTO_FAIL = 'COVER_PHOTO_FAIL';

export const ADD_PHOTO_CASE = 'ADD_PHOTO_CASE';
export const PHOTOS_DOWNLOADED = 'PHOTOS_DOWNLOADED';
export const MARK_AS_FAVORITE = 'MARK_AS_FAVORITE';
export const DELETE_PHOTO_CASE = 'DELETE_PHOTO_CASE';
export const CLEAR_INFO_PHOTO_CASE = 'CLEAR_INFO_PHOTO_CASE';
export const CHECK_PHOTO_CASE = 'CHECK_PHOTO_CASE';
export const CHECK_MARKET_CASE = 'CHECK_MARKET_CASE';
export const MODAL_PAGE = 'MODAL_PAGE';
export const CLEAR_MODAL_PAGE = 'CLEAR_MODAL_PAGE';
export const IS_FROM = 'IS_FROM';

export const CASE_DETAIL_REQUEST = 'CASE_DETAIL_REQUEST';
export const CASE_DETAIL_SUCCESS = 'CASE_DETAIL_SUCCESS';
export const CASE_DETAIL_FAIL = 'CASE_DETAIL_FAIL';
export const CASE_DETAIL_CLEAN = 'CASE_DETAIL_CLEAN';

export const CLEAN_CURRENT_CASE = 'CLEAN_CURRENT_CASE';

export const TRANSFER_STATUS_REQUEST = 'TRANSFER_STATUS_REQUEST';
export const TRANSFER_STATUS_SUCCESS = 'TRANSFER_STATUS_SUCCESS';
export const TRANSFER_STATUS_FAIL = 'TRANSFER_STATUS_FAIL';

export const VEHICLE_CASE_UPDATE_REQUEST = 'VEHICLE_CASE_UPDATE_REQUEST';
export const VEHICLE_CASE_UPDATE_SUCCESS = 'VEHICLE_CASE_UPDATE_SUCCESS';
export const VEHICLE_CASE_UPDATE_FAIL = 'VEHICLE_CASE_UPDATE_FAIL';

export const MARKETS_ALLOWED_REQUEST = 'MARKETS_ALLOWED_REQUEST';
export const MARKETS_ALLOWED_SUCCESS = 'MARKETS_ALLOWED_SUCCESS';
export const MARKETS_ALLOWED_FAIL = 'MARKETS_ALLOWED_FAIL';

export const SET_CASE_IMAGES_LOADING = 'SET_CASE_IMAGES_LOADING';

export const CHECK_CASE_TO_ADVERT_REQUEST = 'CHECK_CASE_TO_ADVERT_REQUEST';
export const CHECK_CASE_TO_ADVERT_SUCCESS = 'CHECK_CASE_TO_ADVERT_SUCCESS';
export const CHECK_CASE_TO_ADVERT_FAIL = 'CHECK_CASE_TO_ADVERT_FAIL';

export const REGISTER_DELIVERY_REQUEST = 'REGISTER_DELIVERY_REQUEST';
export const REGISTER_DELIVERY_SUCCESS = 'REGISTER_DELIVERY_SUCCESS';
export const REGISTER_DELIVERY_FAIL = 'REGISTER_DELIVERY_FAIL';

export const DELIVERY_INFO_REQUEST = 'DELIVERY_INFO_REQUEST';
export const DELIVERY_INFO_SUCCESS = 'DELIVERY_INFO_SUCCESS';
export const DELIVERY_INFO_FAIL = 'DELIVERY_INFO_FAIL';

export const POST_BDELIVERY_REQUEST = 'POST_BDELIVERY_REQUEST';
export const POST_BDELIVERY_SUCCESS = 'POST_BDELIVERY_SUCCESS';
export const POST_BDELIVERY_FAIL = 'POST_BDELIVERY_FAIL';

export const GET_INVENTORY_HISTORY_REQUEST = 'GET_INVENTORY_HISTORY_REQUEST';
export const GET_INVENTORY_HISTORY_SUCCESS = 'GET_INVENTORY_HISTORY_SUCCESS';
export const GET_INVENTORY_HISTORY_FAIL = 'GET_INVENTORY_HISTORY_FAIL';

export const CLEAN_INCOMING_DETAIL = 'CLEAN_INCOMING_DETAIL';

export const SAVE_VIDEO_URL_REQUEST = 'SAVE_VIDEO_URL_REQUEST';
export const SAVE_VIDEO_URL_SUCCESS = 'SAVE_VIDEO_URL_SUCCESS';
export const SAVE_VIDEO_URL_FAIL = 'SAVE_VIDEO_URL_FAIL';

export const GET_VIDEO_URL_REQUEST = 'GET_VIDEO_URL_REQUEST';
export const GET_VIDEO_URL_SUCCESS = 'GET_VIDEO_URL_SUCCESS';
export const GET_VIDEO_URL_FAIL = 'GET_VIDEO_URL_FAIL';

export const DELETE_VIDEO_URL_REQUEST = 'DELETE_VIDEO_URL_REQUEST';
export const DELETE_VIDEO_URL_SUCCESS = 'DELETE_VIDEO_URL_SUCCESS';
export const DELETE_VIDEO_URL_FAIL = 'DELETE_VIDEO_URL_FAIL';

export const GET_ZIP_PICS_REQUEST = 'GET_ZIP_PICS_REQUEST';
export const GET_ZIP_PICS_SUCCESS = 'GET_ZIP_PICS_SUCCESS';
export const GET_ZIP_PICS_FAIL = 'GET_ZIP_PICS_FAIL';

export const GET_CASE_LOGS_REQUEST = 'GET_CASE_LOGS_REQUEST';
export const GET_CASE_LOGS_SUCCESS = 'GET_CASE_LOGS_SUCCESS';
export const GET_CASE_LOGS_FAIL = 'GET_CASE_LOGS_FAIL';


export function getInventoryHistoryRequest(payload) {
  return {
    type: GET_INVENTORY_HISTORY_REQUEST,
    payload
  };
}

export function getInventoryHistorySuccess(payload) {
  return {
    type: GET_INVENTORY_HISTORY_SUCCESS,
    payload: payload
  };
}

export function getInventoryHistoryFail() {
  return {
    type: GET_INVENTORY_HISTORY_FAIL
  };
}

export function postBdeliveryRequest(payload) {
  return {
    type: POST_BDELIVERY_REQUEST,
    payload
  };
}

export function postBdeliverySuccess(payload) {
  return {
    type: POST_BDELIVERY_SUCCESS,
    payload: payload
  };
}

export function postBdeliveryFail() {
  return {
    type: POST_BDELIVERY_FAIL
  };
}

export function deliveryInfoRequest(payload) {
  return {
    type: DELIVERY_INFO_REQUEST,
    payload
  };
}

export function deliveryInfoSuccess(payload) {
  return {
    type: DELIVERY_INFO_SUCCESS,
    payload: payload
  };
}

export function deliveryInfoFail() {
  return {
    type: DELIVERY_INFO_FAIL
  };
}

export function registerDeliveryRequest(payload) {
  return {
    type: REGISTER_DELIVERY_REQUEST,
    payload
  };
}

export function registerDeliverySuccess(payload) {
  return {
    type: REGISTER_DELIVERY_SUCCESS,
    payload: payload
  };
}

export function registerDeliveryFail() {
  return {
    type: REGISTER_DELIVERY_FAIL
  };
}

export function requestDownloadDiverseFile(payload) {
  return {
    type: REQUEST_DOWNLOAD_DIVERSES_FILE,
    payload
  };
}

export function downloadDiverseFileSuccess(payload) {
  return {
    type: DOWNLOAD_DIVERSES_FILE_SUCCESS,
    payload: payload
  };
}

export function downloadDiverseFileFail() {
  return {
    type: DOWNLOAD_DIVERSES_FILE_FAIL
  };
}

export function requestUploadDiverseFile(payload) {
  return {
    type: REQUEST_UPLOAD_DIVERSES_FILE,
    payload
  };
}

export function uploadDiverseFileSuccess(payload) {
  return {
    type: UPLOAD_DIVERSES_FILE_SUCCESS,
    payload: payload
  };
}

export function uploadDiverseFileFail() {
  return {
    type: UPLOAD_DIVERSES_FILE_FAIL
  };
}

export function requestDiverseFiles(payload) {
  return {
    type: REQUEST_DIVERSES_FILES,
    payload
  };
}

export function diverseFilesSuccess(payload) {
  return {
    type: DIVERSES_FILES_SUCCESS,
    payload: payload
  };
}

export function diverseFilesFail() {
  return {
    type: DIVERSES_FILES_FAIL
  };
}

export function requestFavoriteCasePhoto(payload) {
  return {
    type: REQUEST_FAVORITE_CASE_PHOTO,
    payload
  };
}

export function favoriteCaseImagesSuccess(data) {
  return {
    type: FAVORITE_CASE_PHOTO_SUCCESS,
    photos: data
  };
}

export function favoriteCaseImagesFail() {
  return {
    type: FAVORITE_CASE_PHOTO_FAIL
  };
}

export function requestCoverPhoto(payload) {
  return {
    type: REQUEST_COVER_PHOTO,
    payload
  };
}

export function changeCasePhotos(data) {
  return {
    type: CHANGE_CASE_PHOTOS,
    photos: data
  };
}

export function coverPhotoFail() {
  return {
    type: COVER_PHOTO_FAIL
  };
}

export function requestUpdateDiverses(payload) {
  return {
    type: REQUEST_UPDATE_DIVERSES,
    payload
  };
}

export function updateDiversesSuccess(data) {
  return {
    type: UPDATE_DIVERSES_SUCCESS,
    photos: data
  };
}

export function updateDiversesFail() {
  return {
    type: UPDATE_DIVERSES_FAIL
  };
}

export function requesDeleteCaseImages(payload) {
  return {
    type: REQUEST_DELETE_CASE_PHOTO,
    payload
  };
}

export function deleteCaseImagesSuccess(data) {
  return {
    type: DELETE_CASE_PHOTO_SUCCESS,
    photos: data
  };
}

export function deleteCaseImagesFail() {
  return {
    type: DELETE_CASE_PHOTO_FAIL
  };
}

export function requestUploadCaseImages(payload) {
  return {
    type: REQUEST_UPLOAD_CASE_PHOTO,
    payload
  };
}

export function uploadCaseImagesSuccess(data) {
  return {
    type: UPLOAD_CASE_PHOTO_SUCCESS,
    photos: data
  };
}

export function uploadCaseImagesFail() {
  return {
    type: UPLOAD_CASE_PHOTO_FAIL
  };
}

export function requestCaseImages(payload) {
  return {
    type: REQUEST_CASE_IMAGES,
    payload
  };
}

export function setCaseImagesLoading(payload) {
  return {
    type: SET_CASE_IMAGES_LOADING,
    payload: payload
  };
}

export function caseImagesSuccess(data) {
  return {
    type: CASE_IMAGES_SUCCESS,
    photos: data
  };
}

export function caseImagesFail() {
  return {
    type: CASE_STATUS_FAIL
  };
}

export function requestCloseCase(payload) {
  return {
    type: REQUEST_CLOSE_CASE,
    payload
  };
}

export function closeCaseSuccess(data) {
  return {
    type: CASE_CLOSE_SUCCESS,
    photos: data
  };
}

export function closeCaseFail() {
  return {
    type: CASE_CLOSE_FAIL
  };
}

export function getCaseDetail(caseId) {
  return {
    type: CASE_DETAIL_REQUEST,
    payload: { caseId }
  };
}

export function caseDetailSuccess(vehicleCase) {
  return {
    type: CASE_DETAIL_SUCCESS,
    payload: { vehicleCase }
  };
}

export function caseDetailFail() {
  return {
    type: CASE_DETAIL_FAIL
  };
}

export function caseDetailClean() {
  return {
    type: CASE_DETAIL_CLEAN
  };
}

export function cleanCurrentCase() {
  return {
    type: CLEAN_CURRENT_CASE
  };
}

export function transferStatusRequest(caseId) {
  return {
    type: TRANSFER_STATUS_REQUEST,
    payload: { caseId }
  };
}

export function transferStatusSuccess(canBeMoved) {
  return {
    type: TRANSFER_STATUS_SUCCESS,
    payload: { canBeMoved }
  };
}

export function transferStatusFail() {
  return {
    type: TRANSFER_STATUS_FAIL
  };
}

export function addPhoto(photo) {
  return {
    type: ADD_PHOTO_CASE,
    photo
  };
}
export function photosDownloaded(wasDownloaded) {
  return {
    type: PHOTOS_DOWNLOADED,
    wasDownloaded
  };
}
export function markAsFavorite(payload) {
  return {
    type: MARK_AS_FAVORITE,
    payload
  };
}
export function isFrom(isFromAudatex) {
  return {
    type: IS_FROM,
    isFromAudatex
  };
}
export function deletePhotoCase(id) {
  return {
    type: DELETE_PHOTO_CASE,
    id
  };
}
export function clearInfoPhotoCase() {
  return {
    type: CLEAR_INFO_PHOTO_CASE
  };
}
export function checkPhoto(photoInfo) {
  return {
    type: CHECK_PHOTO_CASE,
    photoInfo
  };
}
export function checkMarket(marketsInfo) {
  return {
    type: CHECK_MARKET_CASE,
    marketsInfo
  };
}
export function setModalPage(page) {
  return {
    type: MODAL_PAGE,
    page
  };
}
export function clearModalPage() {
  return {
    type: CLEAR_MODAL_PAGE
  };
}

export const cleanIncomingDetailInvt = () => ({
  type: CLEAN_INCOMING_DETAIL
});

export const filterPhotos = (checkedPhotos, photoInfo) => {
  const found = checkedPhotos.find(ph => ph.fileId === photoInfo.fileId);
  let images = [];
  if (found) {
    images = checkedPhotos.filter(ph => ph.fileId !== photoInfo.fileId);
    // console.log('bb images', checkedPhotos);
    images = [...images];
  } else {
    images = [...checkedPhotos, photoInfo];
  }
  return images;
};
export const dispatchPhotosInfo = photoInfo => (dispatch, getState) => {
  const { checkedPhotos } = getState().case;
  const found = checkedPhotos.find(ph => ph.fileId === photoInfo.fileId);
  let images = [];
  if (found) {
    images = checkedPhotos.filter(ph => ph.fileId !== photoInfo.fileId);
    images = [...images];
  } else {
    images = [...checkedPhotos, photoInfo];
  }
  dispatch(checkPhoto(images));
};
export const setAsFavorite = photoInfo => (dispatch, getState) => {
  const { checkedPhotos } = getState().case;

  let images = [];
  const found = checkedPhotos.find(ph => ph.fileId === photoInfo.fileId);
  if (found) {
    images = checkedPhotos.filter(ph => ph.fileId !== photoInfo.fileId);
    if (found.asFavorite) {
      images = [...images, { ...photoInfo, asFavorite: false }];
    } else {
      images = [...images, photoInfo];
    }
  } else {
    images = [...checkedPhotos, photoInfo];
    const checked = filterPhotos(checkedPhotos, photoInfo);
    dispatch(checkPhoto(checked));
  }

  dispatch(markAsFavorite(images));
};

export const dispatchMarketInfo = marketsInfo => dispatch => {
  let markets = [];
  markets = [...marketsInfo];
  dispatch(checkMarket(markets));
};

export const vehicleCaseRequest = payload => {
  return {
    type: VEHICLE_CASE_UPDATE_REQUEST,
    payload
  };
};

export const vehicleCaseSuccess = () => {
  return {
    type: VEHICLE_CASE_UPDATE_SUCCESS
  };
};

export const vehicleCaseFail = () => {
  return {
    type: VEHICLE_CASE_UPDATE_FAIL
  };
};

export const marketAllowedRequest = payload => {
  return {
    type: MARKETS_ALLOWED_REQUEST,
    payload
  };
};

export const marketsAllowedSuccess = data => {
  return {
    type: MARKETS_ALLOWED_SUCCESS,
    payload: data
  };
};

export const marketsAllowedFail = () => {
  return {
    type: MARKETS_ALLOWED_FAIL
  };
};

export const requestCheckCaseToAdvert = payload => {
  return {
    type: CHECK_CASE_TO_ADVERT_REQUEST,
    payload
  };
};

export const checkCaseToAdvertSuccess = payload => {
  return {
    type: CHECK_CASE_TO_ADVERT_SUCCESS,
    payload
  };
};

export const checkCaseToAdvertFail = () => {
  return {
    type: CHECK_CASE_TO_ADVERT_FAIL
  };
};

export const saveVideoUrlRequest = payload => {
  return {
    type: SAVE_VIDEO_URL_REQUEST,
    payload
  };
};

export const saveVideoUrlSuccess = payload => {
  return {
    type: SAVE_VIDEO_URL_SUCCESS,
    payload
  };
};

export const saveVideoUrlFail = () => {
  return {
    type: SAVE_VIDEO_URL_FAIL
  };
};

export const getVideoUrlRequest = payload => {
  return {
    type: GET_VIDEO_URL_REQUEST,
    payload
  };
};

export const getVideoUrlSuccess = payload => {
  return {
    type: GET_VIDEO_URL_SUCCESS,
    payload
  };
}

export const getVideoUrlFail = () => {
  return {
    type: GET_VIDEO_URL_FAIL
  };
}

export const deleteVideoUrlRequest = payload => {
  return {
    type: DELETE_VIDEO_URL_REQUEST,
    payload
  };
}

export const deleteVideoUrlSuccess = payload => {
  return {
    type: DELETE_VIDEO_URL_SUCCESS,
    payload
  };
}

export const deleteVideoUrlFail = () => {
  return {
    type: DELETE_VIDEO_URL_FAIL
  };
}

export const getZipPicsRequest = payload => {
  return {
    type: GET_ZIP_PICS_REQUEST,
    payload
  };
}

export const getZipPicsSuccess = payload => {
  return {
    type: GET_ZIP_PICS_SUCCESS,
    payload
  };
}

export const getZipPicsFail = () => {
  return {
    type: GET_ZIP_PICS_FAIL
  };
}

export const getCaseLogsRequest = payload => {
  return {
    type: GET_CASE_LOGS_REQUEST,
    payload
  };
};

export const getCaseLogsSuccess = payload => {
  return {
    type: GET_CASE_LOGS_SUCCESS,
    payload
  };
};

export const getCaseLogsFail = () => {
  return {
    type: GET_CASE_LOGS_FAIL
  };
};
