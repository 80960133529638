export const PAGINATION_LIMIT_REACHED = 'PAGINATION_LIMIT_REACHED';
export const PAGINATION_LOAD_PAGE_REQUEST = 'PAGINATION_LOAD_PAGE_REQUEST ';
export const PAGINATION_LOAD_PAGE_SUCCESS = 'PAGINATION_LOAD_PAGE_SUCCESS';
export const PAGINATION_LOAD_PAGE_FAIL = 'PAGINATION_LOAD_PAGE_FAIL ';
export const PAGINATION_CURRENT_PAGE = 'PAGINATION_CURRENT_PAGE';

export function paginationCurrentPage(payload) {
  return {
    type: PAGINATION_CURRENT_PAGE,
    payload
  };
}
