export const TOGGLE_SLIDE = 'TOGGLE_SLIDE';
export const DELETE_FROM_FILE = 'DELETE_FROM_FILE';
export const ADD_FILE = 'ADD_FILE';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const REQUEST_ADD_FILE = 'REQUEST_ADD_FILE';
export const UPDATE_ATTACHMENTS = 'UPDATE_ATTACHMENTS';
export const CLEAN_ATTACHMENTS = 'CLEAN_ATTACHMENTS';

export function cleanAttachments() {
  return {
    type: CLEAN_ATTACHMENTS
  };
}

export function updateDocuments(payload) {
  return {
    type: UPDATE_ATTACHMENTS,
    payload
  };
}

export function toggleSlide(payload) {
  return {
    type: TOGGLE_SLIDE,
    payload
  };
}

export function deleteFromFile(payload) {
  return {
    type: DELETE_FROM_FILE,
    payload
  };
}

export function requestAdd(payload) {
  return {
    type: ADD_FILE,
    payload
  };
}

export function addFile(payload) {
  return {
    type: ADD_FILE,
    payload
  };
}

export function addDocument(payload) {
  return {
    type: ADD_DOCUMENT,
    payload
  };
}
