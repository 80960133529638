export const REQUEST_DAMAGE_COMMENTS = 'REQUEST_DAMAGE_COMMENTS';
export const DAMAGE_COMMENTS_SUCCESS = 'DAMAGE_COMMENTS_SUCCESS';
export const DAMAGE_COMMENTS_FAIL = 'DAMAGE_COMMENTS_FAIL'; 

export const requestDamageComments = payload => {
  return {
    type: REQUEST_DAMAGE_COMMENTS,
    payload
  };
}

export const damageCommentsSuccess = payload => {
  return {
    type: DAMAGE_COMMENTS_SUCCESS,
    payload
  };
}

export const damageCommentsFail = () => {
  return {
    type: DAMAGE_COMMENTS_FAIL
  }
};