import React, { useState, Suspense } from 'react';
import { Col, Button } from 'antd';
import { useTranslation } from "react-i18next";
import '../../assets/css/animations.css';
import LoginForm from '../../components/LoginForm';

import SearchInsuredForm from '../../components/common/SearchInsuredForm';

export function Login(props) {
  const { _handleShowModal } = props;
  const [showSearchInsured, toggleSearchInsured] = useState(false);

  const toggleView = () => {
    let current = showSearchInsured;
    toggleSearchInsured(!current);
  };

  const { t } = useTranslation();

  return (
    <section aria-label="main-login-page">
      <Col className="no-float text-center" xs={24} sm={24} md={20} lg={16} xl={16}>
        <LoginForm setShowModal={_handleShowModal} />
        <div
          className={`changeView ${showSearchInsured ? 'visibleFormInsured' : 'hideFormInsured'}`}
        >
          <Button type="link" onClick={toggleView} className="dark-gray-color">
            {t('loginform.registreinsurer')}
          </Button>
        </div>

        <div className={`searchInsuredForm ${showSearchInsured ? 'fadeIn' : 'fadeOut'}`}>
          {showSearchInsured && (
            <Suspense fallback={<h2>Espere...</h2>}>
              <SearchInsuredForm toggleView={toggleView} showSearchInsured={showSearchInsured} />
            </Suspense>
          )}
        </div>
      </Col>
    </section>
  );
}

export default Login;
