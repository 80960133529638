import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin, Icon } from 'antd';
import { activationPasswordRequest } from 'redux/actions/authActions';
import Styles from './reset-password-page.module.css';

const antIcon = <Icon type="loading" style={{ fontSize: 48 }} spin />;

const ResetPasswordPage = props => {
  const {
    match: {
      params: { id }
    },
    history,
    activationPasswordRequest: activationPasswordReq
  } = props;

  const [messageShow, setMessageShow] = useState('Activando tu contraseña');
  const [secondMessage, setSecondMessage] = useState('Espera un momento');
  const [error, setError] = useState(false);

  const redirect = useCallback(
    time => {
      setTimeout(() => {
        history.push('/');
      }, time);
    },
    [history]
  );

  const onSuccess = useCallback(
    resp => {
      setMessageShow('');
      setSecondMessage('Redirigiendo');
      redirect(300);
    },
    [redirect]
  );

  const onError = useCallback(
    resp => {
      setSecondMessage('Redirigiendo');
      setError(true);
      redirect(4000);
    },
    [redirect]
  );

  useEffect(() => {
    const data = {
      userId: id
    };
    setTimeout(() => {
      activationPasswordReq({ dataRequest: data, onSuccess: onSuccess, onError: onError });
    }, 1500);
  }, [activationPasswordReq, id, onError, onSuccess]);

  return (
    <div className={Styles['container-page-reset-password']}>
      {!error && <p>{messageShow}</p>}
      <p>{secondMessage}</p>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default withRouter(connect(null, { activationPasswordRequest })(ResetPasswordPage));
