export const MENU_REQUEST = 'MENU_REQUEST';
export const MENU_SET = 'MENU_SET';
export const MENU_FAIL = 'MENU_FAIL';
export const USER_SET_DATA = 'USER_SET_DATA';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const PROFILE_INFO_REQUEST = 'PROFILE_INFO_REQUEST';
export const PROFILE_INFO_SUCCESS = 'PROFILE_INFO_SUCCESS';
export const PROFILE_INFO_FAIL = 'PROFILE_INFO_FAIL';

export function changePasswordRequest(payload) {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload
  };
}
export function changePasswordSuccess(payload) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload
  };
}
export function changePasswordFail() {
  return {
    type: CHANGE_PASSWORD_FAIL
  };
}

export function userSetData(data) {
  return {
    type: USER_SET_DATA,
    user: data
  };
}

/**start menu */
export function requestMenu() {
  return {
    type: MENU_REQUEST
  };
}
export function setMenu(data) {
  return {
    type: MENU_SET,
    menu: data
  };
}
export function menuFail() {
  return {
    type: MENU_FAIL
  };
}

export function toggleSideBar({ isCollapsed }) {
  return {
    type: TOGGLE_SIDEBAR,
    isCollapsed
  };
}
/**end menu */

export function profileInfoRequest(payload) {
  return {
    type: PROFILE_INFO_REQUEST,
    payload
  };
}

export function profileInfoSuccess(payload) {
  return {
    type: PROFILE_INFO_SUCCESS,
    payload
  };
}

export function profileInfoFail(payload) {
  return {
    type: PROFILE_INFO_FAIL,
    payload
  };
}
