export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SET_SESSION = 'SET_SESSION';
export const SESSION_ACTIVE = 'SESSION_ACTIVE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const ACTIVATION_PASSWORD_REQUEST = 'ACTIVATION_PASSWORD_REQUEST';
export const ACTIVATION_PASSWORD_SUCCESS = 'ACTIVATION_PASSWORD_SUCCESS';
export const ACTIVATION_PASSWORD_FAIL = 'ACTIVATION_PASSWORD_FAIL';
export const VALID_SESSION = 'VALID_SESSION';
/***---------------ACTIONS CREATORS---------------- */

export function setSession() {
  return {
    type: SET_SESSION
  };
}
export function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload: payload
  };
}

export function loginFailAct() {
  return {
    type: SESSION_ACTIVE
  };
}

export function loginFail() {
  return {
    type: LOGIN_FAIL
  };
}
export function requestLogOut() {
  return {
    type: LOGOUT_USER
  };
}
export function requestLogin(payload) {
  return {
    type: LOGIN_REQUEST,
    payload
  };
}

export function refreshToken(payload) {
  return {
    type: REFRESH_TOKEN,
    payload
  };
}

export function validSession(payload) {
  return {
    type: VALID_SESSION,
    payload
  };
}

export function forceLogout(payload) {
  return {
    type: FORCE_LOGOUT,
    payload
  };
}

export function resetPasswordRequest(payload) {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload
  };
}
export function resetPasswordSuccess(payload) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload
  };
}
export function resetPasswordFail() {
  return {
    type: RESET_PASSWORD_FAIL
  };
}

export function activationPasswordRequest(payload) {
  return {
    type: ACTIVATION_PASSWORD_REQUEST,
    payload
  };
}
export function activationPasswordSuccess(payload) {
  return {
    type: ACTIVATION_PASSWORD_SUCCESS,
    payload
  };
}
export function activationPasswordFail() {
  return {
    type: ACTIVATION_PASSWORD_FAIL
  };
}
