export const DELIVERY_INFO_ADMINS_REQUEST = 'DELIVERY_INFO_ADMINS_REQUEST';
export const DELIVERY_INFO_ADMINS_SUCCESS = 'DELIVERY_INFO_ADMINS_SUCCESS';
export const DELIVERY_INFO_ADMINS_FAIL = 'DELIVERY_INFO_ADMINS_FAIL';

export const DELIVERY_ORDER_DOWNLOAD_REQUEST = 'DELIVERY_ORDER_DOWNLOAD_REQUEST';
export const DELIVERY_ORDER_DOWNLOAD_SUCCESS = 'DELIVERY_ORDER_DOWNLOAD_SUCCESS';
export const DELIVERY_ORDER_DOWNLOAD_FAIL = 'DELIVERY_ORDER_DOWNLOAD_FAIL';

export function deliveryInfoAdminsRequest(payload) {
  return {
    type: DELIVERY_INFO_ADMINS_REQUEST,
    payload
  };
}

export function deliveryInfoAdminsSuccess(payload) {
  return {
    type: DELIVERY_INFO_ADMINS_SUCCESS,
    payload: payload
  };
}

export function deliveryInfoAdminsFail() {
  return {
    type: DELIVERY_INFO_ADMINS_FAIL
  };
}

export function deliveryOrderDownloadRequest(payload) {
  return {
    type: DELIVERY_ORDER_DOWNLOAD_REQUEST,
    payload
  };
}

export function deliveryOrderDownloadSuccess(payload) {
  return {
    type: DELIVERY_ORDER_DOWNLOAD_SUCCESS,
    payload: payload
  };
}

export function deliveryOrderDownloadFail() {
  return {
    type: DELIVERY_ORDER_DOWNLOAD_FAIL
  };
}
