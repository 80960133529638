export const CASE_FILES_REQUEST = 'CASE_FILES_REQUEST';
export const CASE_FILES_SUCCESS = 'CASE_FILES_SUCCESS';
export const CASE_FILES_FAIL = 'CASE_FILES_FAIL';
export const CASE_FILES_CLEAN = 'ASE_FILES_CLEAN';

export const CASE_FILES_ZIP_REQUEST = 'CASE_FILES_ZIP_REQUEST';
export const CASE_FILES_ZIP_SUCCESS = 'CASE_FILES_ZIP_SUCCESS';
export const CASE_FILES_ZIP_FAIL = 'CASE_FILES_ZIP_FAIL';

export const DOCUMENTS_CASE_REQUEST = 'DOCUMENTS_CASE_REQUEST';
export const DOCUMENTS_CASE_SUCESS = 'DOCUMENTS_CASE_SUCESS';
export const DOCUMENTS_CASE_FAIL = 'DOCUMENTS_CASE_FAIL';

export const GET_GLOBAL_FILE_REQUEST = 'GET_GLOBAL_FILE_REQUEST';
export const GET_GLOBAL_FILE_SUCCESS = 'GET_GLOBAL_FILE_SUCCESS';
export const GET_GLOBAL_FILE_FAIL = 'GET_GLOBAL_FILE_FAIL';

export const SAVE_FILE_RESOURCE = 'SAVE_FILE_RESOURCE';
export const FILE_RESOURCE_SUCESS = 'FILE_RESOURCE_SUCESS';
export const GET_FILE_RESOURCE_SUCCESS='GET_FILE_RESOURCE_SUCCESS';
export const DELETE_FILE_RESOURCE = 'DELETE_FILE_RESOURCE';
export const GET_FILE_RESOURCE = 'GET_FILE_RESOURCE';
export const GET_FILE_RESOURCELIST = 'GET_FILE_RESOURCELIST';
export const GET_FILE_RESOURCELIST_SUCCES='GET_FILE_RESOURCELIST_SUCCES';


export function SaveFileResource(payload) {
  return {
    type: SAVE_FILE_RESOURCE,
    payload: payload
  };
}

export function FileResourceSucess(payload) {
  return {
    type: FILE_RESOURCE_SUCESS,
    payload: payload
  };
}

export function DeleteFileResource(payload) {
  return {
    type: DELETE_FILE_RESOURCE,
    payload: payload
  };
}

export function GetFileResourceSucess(payload) {
  return {
    type: GET_FILE_RESOURCE_SUCCESS,
    payload: payload
  };
}

export function GetFileResource(payload) {
  return {
    type: GET_FILE_RESOURCE,
    payload: payload
  };
}

export function GetFileResourceList(payload) {
  return {
    type: GET_FILE_RESOURCELIST,
    payload: payload
  };
}

export function GetFileResourceListSucces(payload) {
  return {
    type: GET_FILE_RESOURCELIST_SUCCES,
    payload: payload
  };
}


export function caseFilesClean() {
  return {
    type: CASE_FILES_CLEAN
  };
}
export function documentsCaseRequest(payload) {
  return {
    type: DOCUMENTS_CASE_REQUEST,
    payload
  };
}

export function documentsCaseSucess(payload) {
  return {
    type: DOCUMENTS_CASE_SUCESS,
    payload
  };
}

export function documentsCaseFail() {
  return {
    type: DOCUMENTS_CASE_FAIL
  };
}

export function caseFileszipRequest(payload) {
  return {
    type: CASE_FILES_ZIP_REQUEST,
    payload
  };
}

export function caseFileszipSuccess(payload) {
  return {
    type: CASE_FILES_ZIP_SUCCESS,
    payload
  };
}

export function caseFileszipFail() {
  return {
    type: CASE_FILES_ZIP_FAIL
  };
}

export function caseFilesRequest(payload) {
  return {
    type: CASE_FILES_REQUEST,
    payload
  };
}

export function caseFilesSuccess(payload) {
  return {
    type: CASE_FILES_SUCCESS,
    payload
  };
}

export function caseFilesFail() {
  return {
    type: CASE_FILES_FAIL
  };
}

export function getGlobalFileRequest(payload) {
  return {
    type: GET_GLOBAL_FILE_REQUEST,
    payload
  };
}

export function getGlobalFileSuccess(payload) {
  return {
    type: GET_GLOBAL_FILE_SUCCESS,
    payload
  };
}

export function getGlobalFileFail() {
  return {
    type: GET_GLOBAL_FILE_FAIL
  };
}
