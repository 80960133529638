export const REQUEST_STATUS_TRANSFER = 'REQUEST_STATUS_TRANSFER';
export const STATUS_TRANSFER_SUCCESS = 'STATUS_TRANSFER_SUCCESS';
export const ADD_PHOTO_RECOLECTION = 'ADD_PHOTO_RECOLECTION';
export const ADD_PHOTO_DELIVERED = 'ADD_PHOTO_DELIVERED';
export const DELETE_PHOTO_DELIVERED = 'DELETE_PHOTO_DELIVERED';
export const DELETE_PHOTO_RECOLECTION = 'DELETE_PHOTO_RECOLECTION';
export const CLEAR_PHOTOS_RECOLECTION = 'CLEAR_PHOTOS_RECOLECTION';

export const SET_RECOLECTION_PICS_LOADING = 'SET_RECOLECTION_PICS_LOADING';
export const RECOLECTION_PICS_SUCCESS = 'RECOLECTION_PICS_SUCCESS';
export const RECOLECTION_PICS_FAIL = 'RECOLECTION_PICS_FAIL';

export const SET_DELIVERED_PICS_LOADING = 'SET_DELIVERED_PICS_LOADING';
export const DELIVERED_PICS_SUCCESS = 'DELIVERED_PICS_SUCCESS';
export const DELIVERED_PICS_FAIL = 'DELIVERED_PICS_FAIL';

export const POST_TRANSFER_PHOTO_REQUEST = 'POST_TRANSFER_PHOTO_REQUEST';
export const POST_TRANSFER_PHOTO_SUCCESS = 'POST_TRANSFER_PHOTO_SUCCESS';
export const POST_TRANSFER_PHOTO_FAIL = 'POST_TRANSFER_PHOTO_FAIL';

export const DELETE_TRANSFER_PHOTO_REQUEST = 'DELETE_TRANSFER_PHOTO_REQUEST';
export const DELETE_TRANSFER_PHOTO_SUCCESS = 'DELETE_TRANSFER_PHOTO_SUCCESS';
export const DELETE_TRANSFER_PHOTO_FAIL = 'DELETE_TRANSFER_PHOTO_FAIL';
export const MARK_AS_FAVORITE_DELIVERY = 'MARK_AS_FAVORITE_DELIVERY';
export const MARK_AS_FAVORITE_COLLECTED = 'MARK_AS_FAVORITE_COLLECTED';

export const GET_TRANSFER_DETAIL_REQUEST = 'GET_TRANSFER_DETAIL_REQUEST';
export const GET_TRANSFER_DETAIL_SUCCESS = 'GET_TRANSFER_DETAIL_SUCCESS';
export const GET_TRANSFER_DETAIL_FAIL = 'GET_TRANSFER_DETAIL_FAIL';
export const SAVE_TRANSFER_FILTER = 'SAVE_TRANSFER_FILTER';

export const REPORT_TRANSFER_REQUEST = 'REPORT_TRANSFER_REQUEST';
export const REPORT_TRANSFER_SUCCESS = 'REPORT_TRANSFER_SUCCESS';
export const REPORT_TRANSFER_FAIL = 'REPORT_TRANSFER_FAIL';

export const CHANGE_TRANSFER_PICS = 'CHANGE_TRANSFER_PICS';

export function addPhoto(photo) {
  return {
    type: ADD_PHOTO_RECOLECTION,
    photo
  };
}

export function deletePhoto(id) {
  return {
    type: DELETE_PHOTO_RECOLECTION,
    id
  };
}

export function addPhotoDelivered(photo) {
  return {
    type: ADD_PHOTO_DELIVERED,
    photo
  };
}

export function deletePhotoDelivered(fileId) {
  return {
    type: DELETE_PHOTO_DELIVERED,
    fileId
  };
}

export function clearPhotos(name) {
  return {
    type: CLEAR_PHOTOS_RECOLECTION,
    name
  };
}

export function setRecolectionPicsLoading(payload) {
  return {
    type: SET_RECOLECTION_PICS_LOADING,
    payload: payload
  };
}

export function recolectionPicsSuccess(payload) {
  return {
    type: RECOLECTION_PICS_SUCCESS,
    payload: payload
  };
}

export function recolectionPicsFail(name) {
  return {
    type: RECOLECTION_PICS_FAIL,
    name
  };
}

export function setDeliveredPicsLoading(payload) {
  return {
    type: SET_DELIVERED_PICS_LOADING,
    payload: payload
  };
}

export function deliveredPicsSuccess(payload) {
  return {
    type: DELIVERED_PICS_SUCCESS,
    payload: payload
  };
}

export function deliveredPicsFail() {
  return {
    type: DELIVERED_PICS_FAIL
  };
}

export function postTransferPhotoRequest(payload) {
  return {
    type: POST_TRANSFER_PHOTO_REQUEST,
    payload: payload
  };
}

export function postTransferPhotoSuccess(payload) {
  return {
    type: POST_TRANSFER_PHOTO_SUCCESS,
    payload: payload
  };
}

export function postTransferPhotoFail() {
  return {
    type: POST_TRANSFER_PHOTO_FAIL
  };
}

export function deleteTransferPhotoRequest(payload) {
  return {
    type: DELETE_TRANSFER_PHOTO_REQUEST,
    payload: payload
  };
}

export function deleteTransferPhotoSuccess(payload) {
  return {
    type: DELETE_TRANSFER_PHOTO_SUCCESS,
    payload: payload
  };
}

export function deleteTransferPhotoFail() {
  return {
    type: DELETE_TRANSFER_PHOTO_FAIL
  };
}

export function markAsFavoriteDelivered(payload) {
  return {
    type: MARK_AS_FAVORITE_DELIVERY,
    payload
  };
}

export function markAsFavoriteCollected(payload) {
  return {
    type: MARK_AS_FAVORITE_COLLECTED,
    payload
  };
}

export function getTransferDetailRequest(payload) {
  return {
    type: GET_TRANSFER_DETAIL_REQUEST,
    payload
  };
}

export function getTransferDetailSuccess(payload) {
  return {
    type: GET_TRANSFER_DETAIL_SUCCESS,
    payload
  };
}

export function getTransferDetailFail(payload) {
  return {
    type: GET_TRANSFER_DETAIL_FAIL,
    payload
  };
}

export function saveTransferFilter(payload) {
  return {
    type: SAVE_TRANSFER_FILTER,
    payload
  };
}

export function changeTrasnferPics(payload) {
  return {
    type: CHANGE_TRANSFER_PICS,
    payload
  };
}

export function reportTransferRequest(payload) {
  return {
    type: REPORT_TRANSFER_REQUEST,
    payload
  };
}

export function reportTransferSuccess(payload) {
  return {
    type: REPORT_TRANSFER_SUCCESS,
    payload
  };
}

export function reportTransferFail(payload) {
  return {
    type: REPORT_TRANSFER_FAIL,
    payload
  };
}