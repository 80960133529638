export const documentCommentsTableResume = [
    {
      align: 'center',
      title: 'cases.search.caseid',
      key: 'caseid',
      dataIndex: 'caseid',
      render: (text, record) => record.caseId
    },
    {
      align: 'center',
      title: 'system.termination',
      key: 'termination',
      dataIndex: 'termination',
      render: (text, record) => {
        return getDocumentById(record.documents, 10);
      }
    },
    {
      align: 'center',
      title: 'payment.detail.proofpayment',
      key: 'proofpayment',
      dataIndex: 'proofpayment',
      render: (text, record) => {
        return getDocumentById(record.documents, 12);
      }
    },
    {
      align: 'center',
      title: 'cases.search.policy',
      key: 'policy',
      dataIndex: 'policy',
      render: (text, record) => {
        return getDocumentById(record.documents, 13);
      }
    },
    {
      align: 'center',
      title: 'system.total.lost.letter',
      key: 'letter',
      dataIndex: 'letter',
      render: (text, record) => {
        return getDocumentById(record.documents, 14);
      }
    },
    {
      align: 'center',
      title: 'system.termination.to.indemnify',
      key: 'indemnify',
      dataIndex: 'indemnify',
      render: (text, record) => {
        return getDocumentById(record.documents, 33);
      }
    },
    {
      align: 'center',
      title: 'system.article.140',
      key: 'article',
      dataIndex: 'article',
      render: (text, record) => {
        return getDocumentById(record.documents, 15);
      }
    },
    {
      align: 'center',
      title: 'system.responsibility.line.format',
      key: 'format',
      dataIndex: 'format',
      render: (text, record) => {
        return getDocumentById(record.documents, 16);
      }
    },
    {
      align: 'center',
      title: 'system.oficial.identification',
      key: 'identification',
      dataIndex: 'identification',
      render: (text, record) => {
        return getDocumentById(record.documents, 17);
      }
    },
    {
      align: 'center',
      title: 'system.bank.statement',
      key: 'statement',
      dataIndex: 'statement',
      render: (text, record) => {
        return getDocumentById(record.documents, 18);
      }
    },
    {
      align: 'center',
      title: 'moralbuyers.step2.proofaddress',
      key: 'proofaddress',
      dataIndex: 'proofaddress',
      render: (text, record) => {
        return getDocumentById(record.documents, 19);
      }
    },
    {
      align: 'center',
      title: 'system.constitutive.act',
      key: 'constitutive',
      dataIndex: 'constitutive',
      render: (text, record) => {
        return getDocumentById(record.documents, 34);
      }
    },
    {
      align: 'center',
      title: 'system.power.attorney',
      key: 'power',
      dataIndex: 'power',
      render: (text, record) => {
        return getDocumentById(record.documents, 35);
      }
    },
    {
      align: 'center',
      title: 'system.identification.attorney',
      key: 'attorney',
      dataIndex: 'attorney',
      render: (text, record) => {
        return getDocumentById(record.documents, 36);
      }
    },
    {
      align: 'center',
      title: 'comment.invoice.origin',
      key: 'origin',
      dataIndex: 'origin',
      render: (text, record) => {
        return getDocumentById(record.documents, 20);
      }
    },
    {
      align: 'center',
      title: 'comment.intermediate.invoices',
      key: 'intermediate',
      dataIndex: 'intermediate',
      render: (text, record) => {
        return getDocumentById(record.documents, 21);
      }
    },
    {
      align: 'center',
      title: 'comment.last.invoice',
      key: 'last',
      dataIndex: 'last',
      render: (text, record) => {
        return getDocumentById(record.documents, 22);
      }
    },
    {
      align: 'center',
      title: 'system.last.invoce.date.creation',
      key: 'date',
      dataIndex: 'date',
      render: (text, record) => {
        const document = record.documents.find(document => document.documentTypeId === 22);
        const metadata = document?.metadata.find(metadata => metadata.id === 10) ?? null;
        return metadata && metadata.text ? metadata.text : '';
      }
    },
    {
      align: 'center',
      title: 'comment.tenure',
      key: 'tenure',
      dataIndex: 'tenure',
      render: (text, record) => {
        return getDocumentById(record.documents, 23);
      }
    },
    {
      align: 'center',
      title: 'comment.plate.deregistration',
      key: 'deregistration',
      dataIndex: 'deregistration',
      render: (text, record) => {
        return getDocumentById(record.documents, 24);
      }
    },
    {
      align: 'center',
      title: 'comment.license.plate.deregistration.receipt',
      key: 'receipt',
      dataIndex: 'receipt',
      render: (text, record) => {
        return getDocumentById(record.documents, 25);
      }
    },
    {
      align: 'center',
      title: 'comment.keys',
      key: 'keys',
      dataIndex: 'keys',
      render: (text, record) => {
        return getDocumentById(record.documents, 26);
      }
    },
    {
      align: 'center',
      title: 'system.thief.act',
      key: 'thief',
      dataIndex: 'thief',
      render: (text, record) => {
        return getDocumentById(record.documents, 27);
      }
    },
    {
      align: 'center',
      title: 'system.liberation',
      key: 'liberation',
      dataIndex: 'liberation',
      render: (text, record) => {
        return getDocumentById(record.documents, 28);
      }
    },
    {
      align: 'center',
      title: 'system.status.repuve.report',
      key: 'repuve',
      dataIndex: 'repuve',
      render: (text, record) => {
        return getDocumentById(record.documents, 29);
      }
    },
    {
      align: 'center',
      title: 'system.others',
      key: 'others',
      dataIndex: 'others',
      render: (text, record) => {
        return getDocumentById(record.documents, 30);
      }
    },
    {
      align: 'center',
      title: 'vehicledocument.commentsondocuments',
      key: 'commentsondocuments',
      dataIndex: 'commentsondocuments',
      render: (text, record) => record.commentsDocuments
    },
];


const getDocumentById = (documents,id) =>{
    const document = documents.find(document => document.documentTypeId === id);
    return document && document.observations ? document.observations : '';
}
    
  