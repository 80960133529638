import { Button, Col, Form, Row } from "antd";
import Title from "antd/lib/skeleton/Title";
import MyButton from "components/common/MyButton";
import MyModal from "components/common/MyModal";
import MyTable from "components/common/MyTable";
import SButton from "components/common/SButton";
import STitle from "components/common/STitle";
import UploadInput from "components/common/UploadInput";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { addMassiveDocuments, addMassiveDocumentsValidate } from "services/files";
import { mainHome, toCasesSearch, validXLSFiles } from "utils/constants";
import { documentCommentsTableResume } from "./ColumnData/documentCommentsTableResume";

const MassiveDocumentComments = (props) => {

    const {
        form
    } = props;

    const initialMount = useRef(false);
    const { t } = useTranslation();
    const [fileIsValidated, setFileIsValidated] = useState(false);
    const [documentData, setDocumentData] = useState();
    const [showRequiredDocument, setRequiredDocument] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [FileResumen, setFileResume] = useState(false);
    const [operationResume, setOperationResume] = useState(false);
    const [showOperationResumeModal, setShowOperationResumeModal] = useState(false);
    const [toggleModal, setToggleModal] = useState(false);

    useEffect(() => {
        initialMount.current = true;
        return () => {
          initialMount.current = false;
        };
      }, []);

    const handleFile = files => {
        if (typeof files !== 'undefined') {
            setDocumentData(files);
            setRequiredDocument(false);
        }
    };

    const validateFile = async (data, saveFile) => {
        try {
            let response = false;
            let isSaving = false;
            if (initialMount.current) {
                setIsRegistering(true);
                console.log('saving');
                if (saveFile) {
                      response = await addMassiveDocuments(data);
                    isSaving = true;
                } else {
                      response = await addMassiveDocumentsValidate(data);
                    if (response.data.data !== false) handleToggleModal();
                }
                setIsRegistering(false);
                if (response && response.status === 200) {
                    const arrayOfResults = response.data;
                    setFileResume(arrayOfResults);
                    if (isSaving) {
                        setOperationResume(response.data.message);
                        setShowOperationResumeModal(true);
                    } else {
                        if (response && response.data && response.data.data && response.data.data.saved.length > 0) {
                            setFileIsValidated(true);
                        } else {
                            setFileIsValidated(false);
                        }
                        if (response.data.data) {
                            handleToggleModal();

                            const arrayOfResults = response.data;
                            setFileResume(arrayOfResults);
                        }
                    }
                }
            }

        } catch (_error) {
            if (initialMount.current) {
                setFileIsValidated(false);
                setIsRegistering(false);
            }
        }
    };

    const handleSubmit = (e, saveFile) => {
        e.preventDefault();
        let existFile = false;
        saveFile = typeof saveFile !== 'undefined' ? saveFile : false;
        if (typeof documentData !== 'undefined' && documentData.data !== '') {
            setRequiredDocument(false);
            existFile = true;
        } else {
            setRequiredDocument(true);
        }
        props.form.validateFields((err, values) => {
            if (!err && existFile) {
                let data = {
                    content: documentData.content
                };
                validateFile({ ...data }, saveFile);
            }
        });
    };

    const handleToggleModal = () => {
        let current = toggleModal;
        setToggleModal(!current);
    };

    const setColumns = () => {
        return documentCommentsTableResume;
    };

    return (
        <>
            <STitle titleText={t('system.bulk.upload.document.comments')} className="text-left" level={3} />
            <Form
                className="no-float text-left"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
                onSubmit={handleSubmit}
            >
                {!fileIsValidated && (
                    <Row gutter={24} justify="end">
                        <Col sm={7} md={7} className="text-right">
                            <UploadInput
                                jest-id="FileUpload"
                                getFileBase64={handleFile}
                                allowSize={10}
                                nameField="Alta masiva de comentarios documentos"
                                formatsText="xlsx"
                                validTypesFile={validXLSFiles}
                                type="button"
                                customMessage={
                                    'El archivo de Alta masiva de comentarios documentos debe ser formato xlsx y tener un tamaño máximo de 10 MB'
                                }
                                instructionLabel={t('system.attachdocument')}
                            />
                            <div className="has-error">
                                {showRequiredDocument && (
                                    <span className="ant-form-explain">{t('cases.massive.documentmandatory')}</span>
                                )}
                            </div>
                        </Col>
                        <Col sm={3} md={3} className="text-left">
                            <MyButton
                                jest-id="ButtonRegistered"
                                label={isRegistering ? t('system.sending') : t('system.send')}
                                type="primary"
                                loading={isRegistering}
                                htmlType="submit"
                                onClick={handleSubmit}
                            />
                        </Col>
                    </Row>
                )}
            </Form>

            {typeof FileResumen.data !== 'undefined' && FileResumen.data.saved.length > 0 && (
                <>
                    <MyTable
                        columns={setColumns()}
                        dataSource={FileResumen.data.saved}
                        scroll={{ x: 'max-content' }}
                    />
                    <Col>
                        <div className="steps-action">
                            <Link to={mainHome}>
                                <Button> Cancelar</Button>
                            </Link>
                            <Button loading={isRegistering} type="primary" onClick={e => handleSubmit(e, true)}>
                                {isRegistering ? 'Espere...' : 'Continuar'}
                            </Button>
                        </div>
                    </Col>
                </>
            )}

            <MyModal
                title={<STitle level={4} titleText={t('massiveinvitationform.filesumary')} />}
                show={toggleModal}
                handleShow={handleToggleModal}
                htmlContent={
                    <div>
                        <div className="text-center">{FileResumen.message}</div>
                        <br />

                        {FileResumen?.data?.errorDetail?.map(name => (
                            <p>{name}</p>
                        ))}
                    </div>
                }
                footer={<MyButton label={t('system.acept')} type="primary" onClick={handleToggleModal} />}
            />
            <MyModal
                title={<Title level={4}>Resumen</Title>}
                show={showOperationResumeModal}
                handleShow={() => props.history.push(toCasesSearch)}
                // handleShow={() => { setShowOperationResumeModal(false);}}
                htmlContent={<div>
                    <div className="text-center">{FileResumen.message}</div>
                    <br />

                    {FileResumen?.data?.errorDetail?.map(name => (
                        <p>{name}</p>
                    ))}
                </div>}
                footer={
                    <SButton label={t('system.acept')} type="primary" onClick={() => props.history.push(toCasesSearch)} />
                }
            />

        </>
    )
}

const MassiveDocumentForm = Form.create({ name: 'massive_document_form' })(MassiveDocumentComments);

export const MassiveDocumentCommentsComponent = connect(null, {})(MassiveDocumentForm);
