export const REPORT_QUEUED_REQUEST = 'REPORT_INCOMING_REQUEST';
export const REPORT_QUEUED_SUCCESS = 'REPORT_INCOMING_SUCCESS';
export const REPORT_QUEUED_FAILURE = 'REPORT_INCOMING_FAILURE';

export const reportQueuedRequest = data => ({
  type: REPORT_QUEUED_REQUEST,
  payload: { data }
});

export const reportQueuedSuccess = data => ({
  type: REPORT_QUEUED_SUCCESS,
  payload: { data }
});

export const reportQueuedFailure = () => ({
  type: REPORT_QUEUED_FAILURE
});
