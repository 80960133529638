export const REQUEST_CASE_TYPE = 'REQUEST_CASE_TYPE';
export const CASE_TYPE_SUCCESS = 'CASE_TYPE_SUCCESS';
export const CASE_TYPE_FAIL = 'CASE_TYPE_FAIL';
export const REQUEST_CASE_STATUS = 'REQUEST_CASE_STATUS';
export const CASE_STATUS_SUCCESS = 'CASE_STATUS_SUCCESS';
export const CASE_STATUS_FAIL = 'CASE_STATUS_FAIL';

export function caseTypeSuccess(caseTypes) {
  return {
    type: CASE_TYPE_SUCCESS,
    caseTypes
  };
}

export function caseTypeFail() {
  return {
    type: CASE_TYPE_FAIL
  };
}

export function requestCaseType() {
  return {
    type: REQUEST_CASE_TYPE
  };
}

export function caseStatusSuccess(caseStatus) {
  return {
    type: CASE_STATUS_SUCCESS,
    caseStatus
  };
}
export function caseStatusFail() {
  return {
    type: CASE_STATUS_FAIL
  };
}

export function requestCaseStatus() {
  return {
    type: REQUEST_CASE_STATUS
  };
}
