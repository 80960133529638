export const SET_STEP = 'SET_STEP';
export const SET_WIZARD_FORM = 'SET_WIZARD_FORM';
export const CLEAN_WIZARD_FORM = 'CLEAN_WIZARD_FORM';
export const SET_IMAGES_INFO = 'SET_IMAGES_INFO';
export const SET_IMAGES_SIZE = 'SET_IMAGES_RULE';
export const SET_IMAGES64_CONTENT = 'SET_IMAGES64_CONTENT';
export const SET_USER_IS_LOCK = 'SET_USER_IS_LOCK';

export function setStep(step) {
  return {
    type: SET_STEP,
    step
  };
}

export function setWizardForm(wizard) {
  return {
    type: SET_WIZARD_FORM,
    wizard
  };
}

export function setUserIsLock(userIsLock) {
  return {
    type: SET_USER_IS_LOCK,
    userIsLock
  };
}

export function cleanWizardForm() {
  return {
    type: CLEAN_WIZARD_FORM
  };
}

export function setImagesInfo(imagesInfo) {
  return {
    type: SET_IMAGES_INFO,
    imagesInfo
  };
}
