export const LAST_PICS_REQUEST = 'LAST_PICS_REQUEST';
export const TRANSFER_LAST_PICS_REQUEST = 'TRANSFER_LAST_PICS_REQUEST';
export const FAV_COUNT_SUCCESS = 'FAV_COUNT_SUCCESS';

export const PICS_AUDATEX_REQUEST = 'PICS_AUDATEX_REQUEST';
export const PICS_AUDATEX_SUCCESS = 'PICS_AUDATEX_SUCCESS';
export const PICS_AUDATEX_FAIL = 'PICS_AUDATEX_FAIL';

export const PICS_AUDATEX_FAV = 'PICS_AUDATEX_FAV';
export const PICS_AUDATEX_CHECK = 'PICS_AUDATEX_CHECK';
export const PICS_AUDATEX_ADD = 'PICS_AUDATEX_ADD';
export const PICS_AUDATEX_REMOVE = 'PICS_AUDATEX_REMOVE';
export const PICS_AUDATEX_SET = 'PICS_AUDATEX_SET';
export const PICS_AUDATEX_SET_BLOCK_REQUEST = 'PICS_AUDATEX_SET_BLOCK_REQUEST';
export const PICS_AUDATEX_SET_PICS_EXIST = 'PICS_AUDATEX_SET_PICS_EXIST';
export const PICS_AUDATEX_CLEAN = 'PICS_AUDATEX_CLEAN';
export const PICS_INVENTORY_CLEAN = 'PICS_INVENTORY_CLEAN';

export const PICS_INVENTORY_INCOMING_REQUEST = 'PICS_INVENTORY_INCOMING_REQUEST';
export const PICS_INVENTORY_OUT_REQUEST = 'PICS_INVENTORY_OUT_REQUEST';
export const PICS_INVENTORY_INCOMING_SUCCESS = 'PICS_INVENTORY_INCOMING_SUCCESS';
export const PICS_INVENTORY_INCOMING_FAIL = 'PICS_INVENTORY_INCOMING_FAIL';

export const PICS_INVENTORY_CHECKIN_ADD = 'PICS_INVENTORY_CHECKIN_ADD';
export const PICS_INVENTORY_CHECKIN_REMOVE = 'PICS_INVENTORY_CHECKIN_REMOVE';
export const PICS_INVENTORY_CHECKIN_FAV = 'PICS_INVENTORY_CHECKIN_FAV';
export const PICS_INVENTORY_CHECKIN_CLEAN = 'PICS_INVENTORY_CHECKIN_CLEAN';
export const PICS_INVENTORY_SET_BLOCK_REQUEST = 'PICS_INVENTORY_SET_BLOCK_REQUEST';
export const PICS_INVENTORY_SET = 'PICS_INVENTORY_SET';
export const PICS_INVENTORY_FAVORITE_REQUEST = 'PICS_INVENTORY_FAVORITE_REQUEST';
export const PICS_INVENTORY_FAVORITE_SUCCESS = 'PICS_INVENTORY_FAVORITE_SUCCESS';
export const PICS_INVENTORY_FAVORITE_FAIL = 'PICS_INVENTORY_FAVORITE_FAIL';

export const PICS_INVENTORY_CHECKOUT_ADD = 'PICS_INVENTORY_CHECKOUT_ADD';
export const PICS_INVENTORY_CHECKOUT_REMOVE = 'PICS_INVENTORY_CHECKOUT_REMOVE';
export const PICS_INVENTORY_CHECKOUT_CLEAN = 'PICS_INVENTORY_CHECKOUT_CLEAN';

export const REMOVE_INCOMING_PHOTO = 'REMOVE_INCOMING_PHOTO';

export function removeIncomingPhoto(payload) {
  return {
    type: REMOVE_INCOMING_PHOTO,
    payload: payload
  };
}

export function lastPicsRequest(payload) {
  return {
    type: LAST_PICS_REQUEST,
    payload: payload
  };
}

export function transferLastPicsRequest(payload) {
  return {
    type: TRANSFER_LAST_PICS_REQUEST,
    payload: payload
  };
}

export function favCountSuccess(payload) {
  return {
    type: FAV_COUNT_SUCCESS,
    payload: payload
  };
}

export function picsAudatexRequest(payload) {
  return {
    type: PICS_AUDATEX_REQUEST,
    payload
  };
}

export function picsAudatexSuccess(payload) {
  return {
    type: PICS_AUDATEX_SUCCESS,
    payload
  };
}

export function picsAudatexFail() {
  return {
    type: PICS_AUDATEX_FAIL
  };
}

export function picsAudatexClean() {
  return {
    type: PICS_AUDATEX_CLEAN
  };
}

export function picsAudatexFav(payload) {
  return {
    type: PICS_AUDATEX_FAV,
    payload: payload
  };
}

export function picsAudatexCheck(payload) {
  return {
    type: PICS_AUDATEX_CHECK,
    payload: payload
  };
}

export function picsAudatexAdd(payload) {
  return {
    type: PICS_AUDATEX_ADD,
    payload: payload
  };
}

export function picsAudatexRemove(payload) {
  return {
    type: PICS_AUDATEX_REMOVE,
    payload: payload
  };
}

export function picsAudatexSet(payload) {
  return {
    type: PICS_AUDATEX_SET,
    payload: payload
  };
}

export function picsAudatexSetBlockReuest(payload) {
  return {
    type: PICS_AUDATEX_SET_BLOCK_REQUEST,
    payload: payload
  };
}

export function picsAudatexSetExist(payload) {
  return {
    type: PICS_AUDATEX_SET_PICS_EXIST,
    payload: payload
  };
}

export function picsInventoryCheckInAdd(payload) {
  return {
    type: PICS_INVENTORY_CHECKIN_ADD,
    payload
  };
}

export function picsInventoryCheckOutAdd(payload) {
  return {
    type: PICS_INVENTORY_CHECKOUT_ADD,
    payload
  };
}

export function picsInventoryCheckInRemove(payload) {
  return {
    type: PICS_INVENTORY_CHECKIN_REMOVE,
    payload
  };
}

export function picsInventoryCheckOutRemove(payload) {
  return {
    type: PICS_INVENTORY_CHECKOUT_REMOVE,
    payload
  };
}

export function picsInventoryCheckInFav(payload) {
  return {
    type: PICS_INVENTORY_CHECKIN_FAV,
    payload
  };
}

export function picsInventoryCheckInClean(payload) {
  return {
    type: PICS_INVENTORY_CHECKIN_CLEAN,
    payload
  };
}

export function picsInventorySetBlockRequest(payload) {
  return {
    type: PICS_INVENTORY_SET_BLOCK_REQUEST,
    payload
  };
}

export function picsInventorySet(payload) {
  return {
    type: PICS_INVENTORY_SET,
    payload: payload
  };
}

export function picsInventoryIncomingRequest(payload) {
  return {
    type: PICS_INVENTORY_INCOMING_REQUEST,
    payload
  };
}
export function picsInventoryOutRequest(payload) {
  return {
    type: PICS_INVENTORY_OUT_REQUEST,
    payload
  };
}
export function picsInventoryIncomingSuccess(payload) {
  return {
    type: PICS_INVENTORY_INCOMING_SUCCESS,
    payload
  };
}

export function picsInventoryIncomingFail() {
  return {
    type: PICS_INVENTORY_INCOMING_FAIL
  };
}

export function picsInventoryCheckOutClean(payload) {
  return {
    type: PICS_INVENTORY_CHECKOUT_CLEAN,
    payload
  };
}

export function picsInventoryClean(payload) {
  return {
    type: PICS_INVENTORY_CLEAN,
    payload
  };
}

export function picsInventoryFavoriteRequest(payload) {
  return {
    type: PICS_INVENTORY_FAVORITE_REQUEST,
    payload
  };
}

export function picsInventoryFavoriteFail(payload) {
  return {
    type: PICS_INVENTORY_FAVORITE_FAIL,
    payload
  };
}

export function picsInventoryFavoriteSuccess(payload) {
  return {
    type: PICS_INVENTORY_FAVORITE_SUCCESS,
    payload
  };
}
