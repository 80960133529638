export const REQUEST_SEARCH_ADVERTS = 'REQUEST_SEARCH_ADVERTS';
export const SEARCH_ADVERTS_SUCCESS = 'SEARCH_ADVERTS_SUCCESS';
export const SEARCH_ADVERTS_FAIL = 'SEARCH_ADVERTS_FAIL';

export const ACTIVE_ADVERT_REQUEST = 'ACTIVE_ADVERT_REQUEST';
export const ACTIVE_ADVERT_SUCCESS = 'ACTIVE_ADVERT_SUCCESS';
export const ACTIVE_ADVERT_FAIL = 'ACTIVE_ADVERT_FAIL';

export const ADVERT_HISTORY_REQUEST = 'ADVERT_HISTORY_REQUEST';
export const ADVERT_HISTORY_SUCCESS = 'ADVERT_HISTORY_SUCCESS';
export const ADVERT_HISTORY_FAIL = 'ADVERT_HISTORY_FAIL';

export const CASES_SEARCH_REQUEST = 'CASES_SEARCH_REQUEST';
export const CASES_SEARCH_SUCCESS = 'CASES_SEARCH_SUCCESS';
export const CASES_SEARCH_FAIL = 'CASES_SEARCH_FAIL';
export const CASES_SEARCH_CANCELLED = 'CASES_SEARCH_CANCELLED';

export const CASES_PRE_SELECT_REQUEST = 'CASES_PRE_SELECT_REQUEST';
export const CASES_PRE_SELECT_SUCCESS = 'CASES_PRE_SELECT_SUCCESS';
export const CASES_PRE_SELECT_FAIL = 'CASES_PRE_SELECT_FAIL';

export const REMOVE_CASE_FROM_ADVERT_REQUEST = 'REMOVE_CASE_FROM_ADVERT_REQUEST';
export const REMOVE_CASE_FROM_ADVERT_SUCCESS = 'REMOVE_CASE_FROM_ADVERT_SUCCESS';
export const REMOVE_CASE_FROM_ADVERT_FAIL = 'REMOVE_CASE_FROM_ADVERT_FAIL';

export const POST_ADVERT_REQUEST = 'POST_ADVERT_REQUEST';
export const POST_ADVERT_SUCCESS = 'POST_ADVERT_SUCCESS';
export const POST_ADVERT_FAIL = 'POST_ADVERT_FAIL';

export const UPDATE_ADVERT_REQUEST = 'UPDATE_ADVERT_REQUEST';
export const UPDATE_ADVERT_SUCCESS = 'UPDATE_ADVERT_SUCCESS';
export const UPDATE_ADVERT_FAIL = 'UPDATE_ADVERT_FAIL';

export const ADVERT_DETAIL_REQUEST = 'ADVERT_DETAIL_REQUEST';
export const ADVERT_DETAIL_SUCCESS = 'ADVERT_DETAIL_SUCCESS';
export const ADVERT_DETAIL_FAIL = 'ADVERT_DETAIL_FAIL';
export const ADVERT_DETAIL_CLEAN = 'ADVERT_DETAIL_CLEAN';

export const HISTORY_ADVERT_REQUEST = 'HISTORY_ADVERT_REQUEST';
export const HISTORY_ADVERT_SUCCESS = 'HISTORY_ADVERT_SUCCESS';
export const HISTORY_ADVERT_FAIL = 'HISTORY_ADVERT_FAIL';

export const CLEAN_ADVERT_SEARCH = 'CLEAN_ADVERT_SEARCH';
export const CLEAN_CURRENT_ADVERT_DETAIL = 'CLEAN_CURRENT_ADVERT_DETAIL';

export const NOTIFY_BUYERS_REQUEST = 'NOTIFY_BUYERS_REQUEST';
export const NOTIFY_BUYERS_SUCCESS = 'NOTIFY_BUYERS_SUCCESS';
export const NOTIFY_BUYERS_FAIL = 'NOTIFY_BUYERS_FAIL';

export const UPDATE_CASE_COSTS_REQUEST = 'UPDATE_CASE_COSTS_REQUEST';
export const UPDATE_CASE_COSTS_SUCCESS = 'UPDATE_CASE_COSTS_SUCCESS';
export const UPDATE_CASE_COSTS_FAIL = 'UPDATE_CASE_COSTS_FAIL';

export const CLEAN_HISTORY_ADVERT = 'CLEAN_HISTORY_ADVERT';

export const CHANGE_ADVERT_STATUS_REQUEST = 'CHANGE_ADVERT_STATUS_REQUEST';
export const CHANGE_ADVERT_STATUS_SUCCESS = 'CHANGE_ADVERT_STATUS_SUCCESS';
export const CHANGE_ADVERT_STATUS_FAIL = 'CHANGE_ADVERT_STATUS_FAIL';

export const ADVERT_RESULT_REQUEST = 'ADVERT_RESULT_REQUEST';
export const ADVERT_RESULT_SUCCESS = 'ADVERT_RESULT_SUCCESS';
export const ADVERT_RESULT_FAIL = 'ADVERT_RESULT_FAIL';
export const SAVE_BUYER_FILTER = 'SAVE_BUYER_FILTER';
export const SAVE_ADVERT_FILTER = 'SAVE_BUYER_FILTER';
export const DOWNLOAD_REPORT_DYNAMIC = 'DOWNLOAD_REPORT_DYNAMIC';
export const DOWNLOAD_REPORT_DYNAMIC_SUCCESS = 'DOWNLOAD_REPORT_DYNAMIC_SUCCESS';

export const DOWNLOAD_REPORT_EXCEL_REQUEST = 'DOWNLOAD_REPORT_EXCEL_REQUEST';
export const DOWNLOAD_REPORT_EXCEL_SUCCESS = 'DOWNLOAD_REPORT_EXCEL_SUCCESS';
export const DOWNLOAD_REPORT_EXCEL_FAIL = 'DOWNLOAD_REPORT_EXCEL_FAIL';

export const UPDATE_ADMINISTRATIVE_COST = 'UPDATE_ADMINISTRATIVE_COST';
export const UPDATE_MARKET_COST = 'UPDATE_MARKET_COST';
export const UPDATE_ADMINISTRATIVE_PERCENTAGE = 'UPDATE_ADMINISTRATIVE_PERCENTAGE';
export const UPDATE_ADMINISTRATIVE_LIMIT = 'UPDATE_ADMINISTRATIVE_LIMIT';
export const UPDATE_ADMINISTRATIVE_AMOUNT = 'UPDATE_ADMINISTRATIVE_AMOUNT';

/***--------------- -ACTIONS CREATORS---------------- */
export function downloadReportDynamic(payload) {
  return {
    type: DOWNLOAD_REPORT_DYNAMIC,
    payload: payload
  };
}
export function downloadReportDynamicSucess(payload) {
  return {
    type: DOWNLOAD_REPORT_DYNAMIC_SUCCESS,
    payload: payload
  };
}
export function requestSearchAdverts(payload) {
  return {
    type: REQUEST_SEARCH_ADVERTS,
    payload: payload
  };
}

export function searchAdvertsSuccess(payload) {
  return {
    type: SEARCH_ADVERTS_SUCCESS,
    payload: payload
  };
}

export function searchAdvertsFail() {
  return {
    type: SEARCH_ADVERTS_FAIL
  };
}
export function requestActiveAdvert(payload) {
  return {
    type: ACTIVE_ADVERT_REQUEST,
    payload: payload
  };
}
export function cleanAdvertsSearch() {
  return {
    type: CLEAN_ADVERT_SEARCH
  };
}

export function activeAdvertSuccess(payload) {
  return {
    type: ACTIVE_ADVERT_SUCCESS,
    payload: payload
  };
}

export function activeAdvertFail() {
  return {
    type: ACTIVE_ADVERT_FAIL
  };
}

export function advertHistoryRequest(payload) {
  return {
    type: ADVERT_HISTORY_REQUEST,
    payload: payload
  };
}

export function advertHistorySuccess(payload) {
  return {
    type: ADVERT_HISTORY_SUCCESS,
    payload: payload
  };
}

export function advertHistoryFail() {
  return {
    type: ADVERT_HISTORY_FAIL
  };
}

export function requestSearchCases(payload) {
  return {
    type: CASES_SEARCH_REQUEST,
    payload
  };
}

export function postAdvertRequest(payload) {
  return {
    type: POST_ADVERT_REQUEST,
    payload: payload
  };
}

export function postAdvertSuccess(payload) {
  return {
    type: POST_ADVERT_SUCCESS,
    payload: payload
  };
}

export function postAdvertFail() {
  return {
    type: POST_ADVERT_FAIL
  };
}

export function requestUpdateAdvert(payload) {
  return {
    type: UPDATE_ADVERT_REQUEST,
    payload
  };
}

export function updateAdvertSuccess(payload) {
  return {
    type: UPDATE_ADVERT_SUCCESS,
    payload
  };
}

export function updateAdvertFail() {
  return {
    type: UPDATE_ADVERT_FAIL
  };
}

export function searchCasesSuccess(payload) {
  return {
    type: CASES_SEARCH_SUCCESS,
    payload
  };
}

export function searchCasesFail() {
  return {
    type: CASES_SEARCH_FAIL
  };
}

export function searchCasesCancelled() {
  return {
    type: CASES_SEARCH_CANCELLED
  };
}

export function requestCasesPreSelect(payload) {
  return {
    type: CASES_PRE_SELECT_REQUEST,
    payload
  };
}

export function casesPreSelectSuccess(payload) {
  return {
    type: CASES_PRE_SELECT_SUCCESS,
    payload
  };
}

export function casesPreSelectFail() {
  return {
    type: CASES_PRE_SELECT_FAIL
  };
}

export function requestAdvertDetail(payload) {
  return {
    type: ADVERT_DETAIL_REQUEST,
    payload
  };
}

export function advertDetailSuccess(payload) {
  return {
    type: ADVERT_DETAIL_SUCCESS,
    payload
  };
}

export function advertDetailFail() {
  return {
    type: ADVERT_DETAIL_FAIL
  };
}

export function advertDetailClean() {
  return {
    type: ADVERT_DETAIL_CLEAN
  };
}

export function removeCaseFromAdvertRequest(payload) {
  return {
    type: REMOVE_CASE_FROM_ADVERT_REQUEST,
    payload
  };
}

export function removeCaseFromAdvertSuccess(payload) {
  return {
    type: REMOVE_CASE_FROM_ADVERT_SUCCESS,
    payload
  };
}

export function removeCaseFromAdvertFail() {
  return {
    type: REMOVE_CASE_FROM_ADVERT_FAIL
  };
}

export function requestHistoryAdvert(payload) {
  return {
    type: HISTORY_ADVERT_REQUEST,
    payload
  };
}

export function historyAdvertSuccess(payload) {
  return {
    type: HISTORY_ADVERT_SUCCESS,
    payload
  };
}

export function historyAdvertFail() {
  return {
    type: HISTORY_ADVERT_FAIL
  };
}

export function cleanHistoryAdvert() {
  return {
    type: CLEAN_HISTORY_ADVERT
  };
}

export function notifyBuyersRequest(payload) {
  return {
    type: NOTIFY_BUYERS_REQUEST,
    payload
  };
}

export function notifyBuyersSucess(payload) {
  return {
    type: NOTIFY_BUYERS_SUCCESS,
    payload
  };
}

export function notifyBuyersFail() {
  return {
    type: NOTIFY_BUYERS_SUCCESS
  };
}

export function requestUpdateCaseCostsRequest(payload) {
  return {
    type: UPDATE_CASE_COSTS_REQUEST,
    payload
  };
}
export function updateCaseCostsSuccess() {
  return {
    type: UPDATE_CASE_COSTS_SUCCESS
  };
}
export function updateCaseCostsFail() {
  return {
    type: UPDATE_CASE_COSTS_FAIL
  };
}

export function cleanCurrentAdvertDetail() {
  return {
    type: CLEAN_CURRENT_ADVERT_DETAIL
  };
}

export function changeAdvertStatusRequest(payload) {
  return {
    type: CHANGE_ADVERT_STATUS_REQUEST,
    payload
  };
}

export function changeAdvertStatusSuccess(payload) {
  return {
    type: CHANGE_ADVERT_STATUS_SUCCESS,
    payload
  };
}

export function changeAdvertStatusFail() {
  return {
    type: CHANGE_ADVERT_STATUS_FAIL
  };
}

export function advertResultRequest(payload) {
  return {
    type: ADVERT_RESULT_REQUEST,
    payload
  };
}

export function advertResultSuccess(payload) {
  return {
    type: ADVERT_RESULT_SUCCESS,
    payload
  };
}

export function advertResultFail() {
  return {
    type: ADVERT_RESULT_FAIL
  };
}

export function saveBuyerFilter(payload) {
  return {
    type: SAVE_BUYER_FILTER,
    payload
  };
}

export function saveAdvertFilter(payload) {
  return {
    type: SAVE_ADVERT_FILTER,
    payload
  };
}

export function downloadReportExcelRequest(payload) {
  return {
    type: DOWNLOAD_REPORT_EXCEL_REQUEST,
    payload
  };
}

export function downloadReportExcelSuccess(payload) {
  return {
    type: DOWNLOAD_REPORT_EXCEL_SUCCESS,
    payload
  };
}

export function downloadReportExcelFail() {
  return {
    type: DOWNLOAD_REPORT_EXCEL_FAIL
  };
}

export function updateAdministrativeCost(payload) {
  return {
    type: UPDATE_ADMINISTRATIVE_COST,
    payload
  };
}

export function updateMarketCost(payload) {
  return {
    type: UPDATE_MARKET_COST,
    payload
  };
}

export function updateAdministrativePercentage(payload) {
  return {
    type: UPDATE_ADMINISTRATIVE_PERCENTAGE,
    payload
  };
}

export function updateAdministrativeLimit(payload) {
  return {
    type: UPDATE_ADMINISTRATIVE_LIMIT,
    payload
  };
}

export function updateAdministrativeAmount(payload) {
  return {
    type: UPDATE_ADMINISTRATIVE_AMOUNT,
    payload
  };
}
/***---------------END OF ACTIONS CREATORS---------------- */
