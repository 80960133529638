export const SEARCH_ADVERTS_4_BUYERS_REQUEST = 'SEARCH_ADVERTS_4_BUYERS_REQUEST';
export const SEARCH_ADVERTS_4_BUYERS_SUCCESS = 'SEARCH_ADVERTS_4_BUYERS_SUCCESS';
export const SEARCH_ADVERTS_4_BUYERS_FAIL = 'SEARCH_ADVERTS_4_BUYERS_FAIL';

export const FAV_ADVERT_REQUEST = 'FAV_ADVERT_REQUEST';
export const FAV_ADVERT_SUCESS = 'FAV_ADVERT_SUCESS';
export const FAV_ADVERT_FAIL = 'FAV_ADVERT_FAIL';

export const REFRESH_ADVERT_LIST_REQUEST = 'REFRESH_ADVERT_LIST_REQUEST';
export const REFRESH_ADVERT_LIST_SUCCESS = 'REFRESH_ADVERT_LIST_SUCCESS';
export const REFRESH_ADVERT_LIST_FAIL = 'REFRESH_ADVERT_LIST_FAIL';

export const BID_ADVERT_REQUEST = 'BID_ADVERT_REQUEST';
export const BID_ADVERT_SUCCESS = 'BID_ADVERT_SUCCESS';
export const BID_ADVERT_FAIL = 'BID_ADVERT_FAIL';

export const BUYER_ADVERT_DETAIL_REQUEST = 'BUYER_ADVERT_DETAIL_REQUEST';
export const BUYER_ADVERT_DETAIL_SUCCESS = 'BUYER_ADVERT_DETAIL_SUCCESS';
export const BUYER_ADVERT_DETAIL_FAIL = 'BUYER_ADVERT_DETAIL_FAIL';
export const BUYER_ADVERT_DETAIL_CLEAN = 'BUYER_ADVERT_DETAIL_CLEAN';
export const BUYER_ADVERT_ONLINE_DETAIL_VISITED = 'BUYER_ADVERT_ONLINE_DETAIL_VISITED';
export const BUYER_ADVERT_ARCHIVED_DETAIL_VISITED = 'BUYER_ADVERT_ARCHIVED_DETAIL_VISITED';

export const AWARDINGS_SEARCH_REQUEST = ' AWARDINGS_SEARCH_REQUEST';
export const AWARDINGS_SEARCH_SUCCESS = ' AWARDINGS_SEARCH_SUCCESS';
export const AWARDINGS_SEARCH_FAIL = 'AWARDINGS_SEARCH_FAIL';

export const AWARDINGS_DETAIL_REQUEST = ' AWARDINGS_DETAIL_REQUEST';
export const AWARDINGS_DETAIL_SUCCESS = ' AWARDINGS_DETAIL_SUCCESS';
export const AWARDINGS_DETAIL_FAIL = 'AWARDINGS_DETAIL_FAIL';
export const AWARDINGS_DETAIL_CLEAN = 'AWARDINGS_DETAIL_CLEAN';

export const BUYER_INVITATION_REQUEST = 'BUYER_INVITATION_REQUEST';
export const BUYER_INVITATION_SUCCESS = 'BUYERS_INVITATION_SUCCESS';
export const BUYER_INVITATION_FAIL = 'BUYER_INVITATION_FAIL';

export const GET_VOUCHERS_REQUEST = 'GET_VOUCHERS_REQUEST';
export const GET_VOUCHERS_SUCCESS = 'GET_VOUCHERS_SUCCESS';
export const GET_VOUCHERS_FAIL = 'GET_VOUCHERS_FAIL';

export const DELETE_VOUCHER_REQUEST = 'DELETE_VOUCHER_REQUEST';
export const DELETE_VOUCHER_SUCCESS = 'DELETE_VOUCHER_SUCCESS';
export const DELETE_VOUCHER_FAIL = 'DELETE_VOUCHER_FAIL';

export const ADD_VOUCHER_REQUEST = 'ADD_VOUCHER_REQUEST';
export const ADD_VOUCHER_SUCCESS = 'ADD_VOUCHER_SUCCESS';
export const ADD_VOUCHER_FAIL = 'ADD_VOUCHER_FAIL';

export const VIEW_VOUCHER_REQUEST = 'VIEW_VOUCHER_REQUEST';
export const VIEW_VOUCHER_SUCCESS = 'VIEW_VOUCHER_SUCCESS';
export const VIEW_VOUCHER_FAIL = 'VIEW_VOUCHER_FAIL';

export const VALIDATE_PAYMENT_REQUEST = 'VALIDATE_PAYMENT_REQUEST';
export const VALIDATE_PAYMENT_SUCCESS = 'VALIDATE_PAYMENT_SUCCESS';
export const VALIDATE_PAYMENT_FAIL = 'VALIDATE_PAYMENT_FAIL';

export const SHOW_REJECT_COMMENTS_REQUEST = 'SHOW_REJECT_COMMENTS_REQUEST';
export const SHOW_REJECT_COMMENTS_SUCCESS = 'SHOW_REJECT_COMMENTS_SUCCESS';
export const SHOW_REJECT_COMMENTS_FAIL = 'SHOW_REJECT_COMMENTS_FAIL';

export const UPDATE_VOUCHER_REQUEST = 'UPDATE_VOUCHER_REQUEST';
export const UPDATE_VOUCHER_SUCCESS = 'UPDATE_VOUCHER_SUCCESS';
export const UPDATE_VOUCHER_FAIL = 'UPDATE_VOUCHER_FAIL';

export const GET_DELIVERY_REQUEST = 'GET_DELIVERY_REQUEST';
export const GET_DELIVERY_SUCCESS = 'GET_DELIVERY_SUCCESS';
export const GET_DELIVERY_FAIL = 'GET_DELIVERY_FAIL';
export const GET_DELIVERY_CLEAN = 'GET_DELIVERY_CLEAN';

export const POST_DELIVERY_COMMENT_REQUEST = 'POST_DELIVERY_COMMENT_REQUEST';
export const POST_DELIVERY_COMMENT_SUCCESS = 'POST_DELIVERY_COMMENT_SUCCESS';
export const POST_DELIVERY_COMMENT_FAIL = 'POST_DELIVERY_COMMENT_FAIL';

export const ADVERT_CARD_MODAL_SET = 'ADVERT_CARD_MODAL_SET';
export const ADVERT_DETAIL_MODAL_SET = 'ADVERT_DETAIL_MODAL_SET';
export const LAST_UPDATED_ADVERT = 'LAST_UPDATED_ADVERT';
export const LAST_DETAIL_VISITED = 'LAST_DETAIL_VISITED';

export const SAVE_BUYER_FILTER = 'SAVE_BUYER_FILTER';

export const SET_ADVERT_IMAGES = 'SET_ADVERT_IMAGES';
export const GET_ADVERT_IMAGES = 'GET_ADVERT_IMAGES';

export const GET_ADVERT_IMAGES_FULL_SCREEN = 'GET_ADVERT_IMAGES_FULL_SCREEN';

export const GET_ADVERT_COMMENTS_REQUEST = 'GET_ADVERT_COMMENTS_REQUEST';
export const GET_ADVERT_COMMENTS_SUCCESS = 'GET_ADVERT_COMMENTS_SUCCESS';
export const GET_ADVERT_COMMENTS_FAIL = 'GET_ADVERT_COMMENTS_FAIL';


export function postDeliveryCommentRequest(payload) {
  return {
    type: POST_DELIVERY_COMMENT_REQUEST,
    payload
  };
}

export function postDeliveryCommentSuccess(payload) {
  return {
    type: POST_DELIVERY_COMMENT_SUCCESS,
    payload
  };
}

export function postDeliveryCommentFail(payload) {
  return {
    type: POST_DELIVERY_COMMENT_FAIL,
    payload
  };
}

export function getDeliveryRequest(payload) {
  return {
    type: GET_DELIVERY_REQUEST,
    payload
  };
}

export function getDeliverySuccess(payload) {
  return {
    type: GET_DELIVERY_SUCCESS,
    payload
  };
}

export function getDeliveryFail(payload) {
  return {
    type: GET_DELIVERY_FAIL,
    payload
  };
}

export function getDeliveryClean(payload) {
  return {
    type: GET_DELIVERY_CLEAN,
    payload
  };
}

export function awardingsDetailClean(payload) {
  return {
    type: AWARDINGS_DETAIL_CLEAN,
    payload
  };
}

export function updateVoucherRequest(payload) {
  return {
    type: UPDATE_VOUCHER_REQUEST,
    payload
  };
}

export function updateVoucherSuccess(payload) {
  return {
    type: UPDATE_VOUCHER_SUCCESS,
    payload
  };
}

export function updateVoucherFail(payload) {
  return {
    type: UPDATE_VOUCHER_FAIL,
    payload
  };
}

export function showRejectCommentsRequest(payload) {
  return {
    type: SHOW_REJECT_COMMENTS_REQUEST,
    payload
  };
}

export function showRejectCommentsSuccess(payload) {
  return {
    type: SHOW_REJECT_COMMENTS_SUCCESS,
    payload
  };
}

export function showRejectCommentsFail(payload) {
  return {
    type: SHOW_REJECT_COMMENTS_FAIL,
    payload
  };
}

export function validatePaymentRequest(payload) {
  return {
    type: VALIDATE_PAYMENT_REQUEST,
    payload
  };
}

export function validatePaymentSuccess(payload) {
  return {
    type: VALIDATE_PAYMENT_SUCCESS,
    payload
  };
}

export function validatePaymentFail(payload) {
  return {
    type: VALIDATE_PAYMENT_FAIL,
    payload
  };
}

export function viewVoucherRequest(payload) {
  return {
    type: VIEW_VOUCHER_REQUEST,
    payload
  };
}

export function viewVoucherSuccess(payload) {
  return {
    type: VIEW_VOUCHER_SUCCESS,
    payload
  };
}

export function viewVoucherFail(payload) {
  return {
    type: VIEW_VOUCHER_FAIL,
    payload
  };
}

export function addVoucherRequest(payload) {
  return {
    type: ADD_VOUCHER_REQUEST,
    payload
  };
}

export function addVoucherSuccess(payload) {
  return {
    type: ADD_VOUCHER_SUCCESS,
    payload
  };
}

export function addVoucherFail(payload) {
  return {
    type: ADD_VOUCHER_FAIL,
    payload
  };
}

export function deleteVoucherRequest(payload) {
  return {
    type: DELETE_VOUCHER_REQUEST,
    payload
  };
}

export function deleteVoucherSuccess(payload) {
  return {
    type: DELETE_VOUCHER_SUCCESS,
    payload
  };
}

export function deleteVoucherFail(payload) {
  return {
    type: DELETE_VOUCHER_FAIL,
    payload
  };
}

export function getVouchersRequest(payload) {
  return {
    type: GET_VOUCHERS_REQUEST,
    payload
  };
}

export function getVouchersSuccess(payload) {
  return {
    type: GET_VOUCHERS_SUCCESS,
    payload
  };
}

export function getVouchersFail(payload) {
  return {
    type: GET_VOUCHERS_FAIL,
    payload
  };
}

export function buyerInvitationRequest(payload) {
  return {
    type: BUYER_INVITATION_REQUEST,
    payload
  };
}

export function buyerInvitationSuccess(payload) {
  return {
    type: BUYER_INVITATION_SUCCESS,
    payload
  };
}

export function buyerInvitationFail(payload) {
  return {
    type: BUYER_INVITATION_FAIL,
    payload
  };
}
export function awardingsDetailRequest(payload) {
  return {
    type: AWARDINGS_DETAIL_REQUEST,
    payload
  };
}

export function awardingsDetailSuccess(payload) {
  return {
    type: AWARDINGS_DETAIL_SUCCESS,
    payload
  };
}

export function awardingsDetailFail() {
  return {
    type: AWARDINGS_DETAIL_FAIL
  };
}

export function awardingsSearchRequest(payload) {
  return {
    type: AWARDINGS_SEARCH_REQUEST,
    payload
  };
}

export function awardingsSearchSuccess(payload) {
  return {
    type: AWARDINGS_SEARCH_SUCCESS,
    payload
  };
}

export function awardingsSearchFail() {
  return {
    type: AWARDINGS_SEARCH_FAIL
  };
}

export function refreshAdvertListRequest(payload) {
  return {
    type: REFRESH_ADVERT_LIST_REQUEST,
    payload
  };
}

export function refreshAdvertListSuccess(payload) {
  return {
    type: REFRESH_ADVERT_LIST_SUCCESS,
    payload
  };
}

export function refreshAdvertListFail() {
  return {
    type: REFRESH_ADVERT_LIST_FAIL
  };
}

export function searchAdverts4BuyersRequest(payload) {
  return {
    type: SEARCH_ADVERTS_4_BUYERS_REQUEST,
    payload
  };
}

export function searchAdverts4BuyersSuccess(payload) {
  return {
    type: SEARCH_ADVERTS_4_BUYERS_SUCCESS,
    payload
  };
}

export function searchAdverts4BuyersFail() {
  return {
    type: SEARCH_ADVERTS_4_BUYERS_FAIL
  };
}

export function favAdvertRequest(payload) {
  return {
    type: FAV_ADVERT_REQUEST,
    payload
  };
}

export function favAdvertSuccess(payload) {
  return {
    type: FAV_ADVERT_SUCESS,
    payload
  };
}

export function favAdvertFail() {
  return {
    type: FAV_ADVERT_FAIL
  };
}

export function bidAdvertRequest(payload) {
  return {
    type: BID_ADVERT_REQUEST,
    payload
  };
}

export function bidAdvertSuccess(payload) {
  return {
    type: BID_ADVERT_SUCCESS,
    payload
  };
}

export function bidAdvertFail() {
  return {
    type: BID_ADVERT_FAIL
  };
}

export function advertDetailRequest(payload) {
  return {
    type: BUYER_ADVERT_DETAIL_REQUEST,
    payload
  };
}

export function advertDetailSuccess(payload) {
  return {
    type: BUYER_ADVERT_DETAIL_SUCCESS,
    payload
  };
}

export function advertDetailFail() {
  return {
    type: BUYER_ADVERT_DETAIL_FAIL
  };
}

export function advertDetailClean() {
  return {
    type: BUYER_ADVERT_DETAIL_CLEAN
  };
}

export function advertOnlineDetailVisited(payload) {
  return {
    type: BUYER_ADVERT_ONLINE_DETAIL_VISITED,
    payload
  };
}

export function advertArchivedDetailVisited(payload) {
  return {
    type: BUYER_ADVERT_ARCHIVED_DETAIL_VISITED,
    payload
  };
}

export function saveBuyerFilter(payload) {
  return {
    type: SAVE_BUYER_FILTER,
    payload
  };
}

export function advertCardModalSet(payload) {
  return {
    type: ADVERT_CARD_MODAL_SET,
    payload
  };
}

export function advertDetailModalSet(payload) {
  return {
    type: ADVERT_DETAIL_MODAL_SET,
    payload
  };
}

export function lastUpdatedAdvert(payload) {
  return {
    type: LAST_UPDATED_ADVERT,
    payload
  };
}

export function lastDetailVisited(payload) {
  return {
    type: LAST_DETAIL_VISITED,
    payload
  };
}

export function setAdvertImages(payload) {
  return {
    type: SET_ADVERT_IMAGES,
    payload
  };
}

export function getAdvertImages(payload) {
  return {
    type: GET_ADVERT_IMAGES,
    payload
  };
}

export function getAdvertImagesFullScreen(payload) {
  return {
    type: GET_ADVERT_IMAGES_FULL_SCREEN,
    payload
  };
}

export function getAdvertCommentsRequest(payload) {
  return {
    type: GET_ADVERT_COMMENTS_REQUEST,
    payload
  };
}

export function getAdvertCommentsSuccess(payload) {
  return {
    type: GET_ADVERT_COMMENTS_SUCCESS,
    payload
  };
}

export function getAdvertCommentsFail(payload) {
  return {
    type: GET_ADVERT_COMMENTS_FAIL,
    payload
  };
}

//
