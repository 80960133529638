export const REQUEST_ADVERT_TYPE = 'REQUEST_ADVERT_TYPE';
export const ADVERT_TYPE_SUCCESS = 'ADVERT_TYPE_SUCCESS';
export const ADVERT_TYPE_FAIL = 'ADVERT_TYPE_FAIL';

export const REQUEST_ADVERT_STATUS = 'REQUEST_ADVERT_STATUS';
export const ADVERT_STATUS_SUCCESS = 'ADVERT_STATUS_SUCCESS';
export const ADVERT_STATUS_FAIL = 'ADVERT_STATUS_FAIL';

export function advertTypeSuccess(advertStatus) {
  return {
    type: ADVERT_TYPE_SUCCESS,
    advertStatus
  };
}

export function advertTypeFail() {
  return {
    type: ADVERT_TYPE_FAIL
  };
}

export function requestAdvertType() {
  return {
    type: REQUEST_ADVERT_TYPE
  };
}

export function advertStatusSuccess(advertStatus) {
  return {
    type: ADVERT_STATUS_SUCCESS,
    advertStatus
  };
}
export function advertStatusFail() {
  return {
    type: ADVERT_STATUS_FAIL
  };
}

export function requestAdvertStatus() {
  return {
    type: REQUEST_ADVERT_STATUS
  };
}
