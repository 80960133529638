export const USER_ADD_REQUEST = 'USER_ADD_REQUEST';
export const USER_ADD_SUCCESS = 'USER_ADD_SUCCESS';
export const USER_ADD_FAIL = 'USER_ADD_FAIL';
export const USER_ADD_FILTER = 'USER_ADD_FILTER';

export const PROOF_GET_REQUEST = 'PROOF_GET_REQUEST';
export const PROOF_GET_SUCCESS = 'PROOF_GET_SUCCESS';
export const PROOF_GET_FAIL = 'PROOF_GET_FAIL';

export const PROOF_ADD_REQUEST = 'PROOF_ADD_REQUEST';
export const PROOF_ADD_SUCCESS = 'PROOF_ADD_SUCCESS';
export const PROOF_ADD_FAIL = 'PROOF_ADD_FAIL';

export const PROOF_DELETE_REQUEST = 'PROOF_DELETE_REQUEST';
export const PROOF_DELETE_SUCCESS = 'PROOF_DELETE_SUCCESS';
export const PROOF_DELETE_FAIL = 'PROOF_DELETE_FAIL';

export const PROOF_TYPE_CHANGE_REQUEST = 'PROOF_TYPE_CHANGE_REQUEST';
export const PROOF_TYPE_CHANGE_SUCCESS = 'PROOF_TYPE_CHANGE_SUCCESS';
export const PROOF_TYPE_CHANGE_FAIL = 'PROOF_TYPE_CHANGE_FAIL';

export const GET_BUYER_ASSOCIATED_USERS_REQUEST = 'GET_BUYER_ASSOCIATED_USERS_REQUEST';
export const GET_BUYER_ASSOCIATED_USERS_SUCCESS = 'GET_BUYER_ASSOCIATED_USERS_SUCCESS';
export const GET_BUYER_ASSOCIATED_USERS_FAIL = 'GET_BUYER_ASSOCIATED_USERS_FAIL';

export const BUYER_ASSOCIATED_USERS_REQUEST = 'BUYER_ASSOCIATED_USERS_REQUEST';
export const BUYER_ASSOCIATED_USERS_SUCCESS = 'BUYER_ASSOCIATED_USERS_SUCCESS';
export const BUYER_ASSOCIATED_USERS_FAIL = 'BUYER_ASSOCIATED_USERS_FAIL';

export const BUYER_ASSOCIATED_USERS_UPDATE_REQUEST = 'BUYER_ASSOCIATED_USERS_UPDATE_REQUEST';
export const BUYER_ASSOCIATED_USERS_UPDATE_SUCCESS = 'BUYER_ASSOCIATED_USERS_UPDATE_SUCCESS';
export const BUYER_ASSOCIATED_USERS_UPDATE_FAIL = 'BUYER_ASSOCIATED_USERS_UPDATE_FAIL';

export const USER_GET_COMMENTS_REQUEST = 'USER_GET_COMMENTS_REQUEST';
export const USER_GET_COMMENTS_SUCCESS = 'USER_GET_COMMENTS_SUCCESS';
export const USER_GET_COMMENTS_FAIL = 'USER_GET_COMMENTS_FAIL';

export function requestAddUser(payload) {
  return {
    type: USER_ADD_REQUEST,
    payload
  };
}
export function addUserSuccess(response) {
  return {
    type: USER_ADD_SUCCESS,
    response
  };
}
export function addUserFail() {
  return {
    type: USER_ADD_FAIL
  };
}
export function addUserFilter(payload) {
  return {
    type: USER_ADD_FILTER,
    payload
  };
}

export function proofGetRequest(payload) {
  return {
    type: PROOF_GET_REQUEST,
    payload
  };
}

export function proofGetSuccess(payload) {
  return {
    type: PROOF_GET_SUCCESS,
    payload
  };
}

export function proofGetFail() {
  return {
    type: PROOF_GET_FAIL
  };
}

export function proofAddRequest(payload) {
  return {
    type: PROOF_ADD_REQUEST,
    payload
  };
}

export function proofAddSuccess(payload) {
  return {
    type: PROOF_ADD_SUCCESS,
    payload
  };
}

export function proofAddFail() {
  return {
    type: PROOF_ADD_FAIL
  };
}

export function proofDeleteRequest(payload) {
  return {
    type: PROOF_DELETE_REQUEST,
    payload
  };
}

export function proofDeleteSuccess(payload) {
  return {
    type: PROOF_DELETE_SUCCESS,
    payload
  };
}

export function proofDeleteFail() {
  return {
    type: PROOF_DELETE_FAIL
  };
}

export function proofTypeChangeRequest(payload) {
  return {
    type: PROOF_TYPE_CHANGE_REQUEST,
    payload
  };
}

export function proofTypeChangeSuccess(payload) {
  return {
    type: PROOF_TYPE_CHANGE_SUCCESS,
    payload
  };
}

export function proofTypeChangeFail() {
  return {
    type: PROOF_TYPE_CHANGE_FAIL
  };
}

export function getBuyerAssociatedUsersRequest(payload) {
  return {
    type: GET_BUYER_ASSOCIATED_USERS_REQUEST,
    payload
  };
}

export function getBuyerAssociatedUsersSuccess(payload) {
  return {
    type: GET_BUYER_ASSOCIATED_USERS_SUCCESS,
    payload
  };
}

export function getBuyerAssociatedUsersFail(payload) {
  return {
    type: GET_BUYER_ASSOCIATED_USERS_FAIL,
    payload
  };
}

export function buyerAssociatedUsersRequest(payload) {
  return {
    type: BUYER_ASSOCIATED_USERS_REQUEST,
    payload
  };
}

export function buyerAssociatedUsersSuccess(payload) {
  return {
    type: BUYER_ASSOCIATED_USERS_SUCCESS,
    payload
  };
}

export function buyerAssociatedUsersFail(payload) {
  return {
    type: BUYER_ASSOCIATED_USERS_FAIL,
    payload
  };
}

export function buyerAssociatedUsersUpdateRequest(payload) {
  return {
    type: BUYER_ASSOCIATED_USERS_UPDATE_REQUEST,
    payload
  };
}

export function buyerAssociatedUsersUpdateSuccess(payload) {
  return {
    type: BUYER_ASSOCIATED_USERS_UPDATE_SUCCESS,
    payload
  };
}

export function buyerAssociatedUsersUpdateFail(payload) {
  return {
    type: BUYER_ASSOCIATED_USERS_UPDATE_FAIL,
    payload
  };
}

export function getUserCommentsRequest(payload) {
  return {
    type: USER_GET_COMMENTS_REQUEST,
    payload
  };
}

export function getUserCommentsSuccess(payload) {
  return {
    type: USER_GET_COMMENTS_SUCCESS,
    payload
  };
}

export function getUserCommentsFail(payload) {
  return {
    type: USER_GET_COMMENTS_FAIL,
    payload
  };
}
