export const COMPONENTS_REQUEST = 'COMPONENTS_REQUEST';
export const COMPONENTS_FLOAT_MENU_SUCCESS = 'COMPONENTS_FLOAT_MENU_SUCCESS';
export const COMPONENTS_HEADER_MENU_SUCCESS = 'COMPONENTS_HEADER_MENU_SUCCESS';
export const COMPONENTS_FAIL = 'COMPONENTS_FAIL';

export function requestComponents(payload) {
    return {
        type: COMPONENTS_REQUEST,
        payload
    };
}   

export function componentsFloatMenuSuccess(payload) {
    return {
        type: COMPONENTS_FLOAT_MENU_SUCCESS,
        payload
    };
}

export function componentsFail() {
    return {
        type: COMPONENTS_FAIL
    };
}

export function componentsHeaderMenuSuccess(payload) {
    return {
        type: COMPONENTS_HEADER_MENU_SUCCESS,
        payload
    };
}