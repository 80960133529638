export const PAYMENTS_SET_RESULTS = 'PAYMENTS_SET_RESULTS';
export const PAYMENTS_CLEAN_RESULTS = 'PAYMENTS_CLEAN_RESULTS';

export const PAYMENT_DETAIL_REQUEST = 'PAYMENT_DETAIL_REQUEST';
export const PAYMENT_DETAIL_SUCCESS = 'PAYMENT_DETAIL_SUCCESS';
export const PAYMENT_DETAIL_FAIL = 'PAYMENT_DETAIL_FAIL';
export const PAYMENT_DETAIL_CLEAN = 'PAYMENT_DETAIL_CLEAN';

export const VALIDATE_PAYMENT_FILE_REQUEST = 'VALIDATE_PAYMENT_FILE_REQUEST';
export const VALIDATE_PAYMENT_FILE_SUCCESS = 'VALIDATE_PAYMENT_FILE_SUCCESS';
export const VALIDATE_PAYMENT_FILE_FAIL = 'VALIDATE_PAYMENT_FILE_FAIL';

export const VALIDATE_PAYMENT_HISTORY_REQUEST = 'VALIDATE_PAYMENT_HISTORY_REQUEST';
export const VALIDATE_PAYMENT_HISTORY_SUCCESS = 'VALIDATE_PAYMENT_HISTORY_SUCCESS';
export const VALIDATE_PAYMENT_HISTORY_FAIL = 'VALIDATE_PAYMENT_HISTORY_FAIL';

export const CANCEL_PAYMENT_REQUEST = 'CANCEL_PAYMENT_REQUEST';
export const CANCEL_PAYMENT_SUCCESS = 'CANCEL_PAYMENT_SUCCESS';
export const CANCEL_PAYMENT_FAIL = 'CANCEL_PAYMENT_FAIL';

export function paymentsCleanResults(payload) {
  return {
    type: PAYMENTS_CLEAN_RESULTS,
    payload
  };
}

export function paymentsSetResults(payload) {
  return {
    type: PAYMENTS_SET_RESULTS,
    payload
  };
}

export function paymentDetailRequest(payload) {
  return {
    type: PAYMENT_DETAIL_REQUEST,
    payload
  };
}

export function paymentDetailSuccess(payload) {
  return {
    type: PAYMENT_DETAIL_SUCCESS,
    payload
  };
}

export function paymentDetailFail() {
  return {
    type: PAYMENT_DETAIL_FAIL
  };
}

export function paymentDetailClean() {
  return {
    type: PAYMENT_DETAIL_CLEAN
  };
}

export function validatePaymentFileRequest(payload) {
  return {
    type: VALIDATE_PAYMENT_FILE_REQUEST,
    payload
  };
}

export function validatePaymentFileSuccess(payload) {
  return {
    type: VALIDATE_PAYMENT_FILE_SUCCESS,
    payload
  };
}

export function validatePaymentFileFail(payload) {
  return {
    type: VALIDATE_PAYMENT_FILE_FAIL,
    payload
  };
}

export function validatePaymentHistoryRequest(payload) {
  return {
    type: VALIDATE_PAYMENT_HISTORY_REQUEST,
    payload
  };
}

export function validatePaymentHistorySuccess(payload) {
  return {
    type: VALIDATE_PAYMENT_HISTORY_SUCCESS,
    payload
  };
}

export function validatePaymentHistoryFail(payload) {
  return {
    type: VALIDATE_PAYMENT_HISTORY_FAIL,
    payload
  };
}

export function cancelPaymentRequest(payload) {
  return {
    type: CANCEL_PAYMENT_REQUEST,
    payload
  };
}

export function cancelPaymentSuccess(payload) {
  return {
    type: CANCEL_PAYMENT_SUCCESS,
    payload
  };
}

export function cancelPaymentFail(payload) {
  return {
    type: CANCEL_PAYMENT_FAIL,
    payload
  };
}
